/* eslint-disable camelcase */
import { Tooltip } from '@/components/tooltip'
import { capitalize } from '@/utils/string'

const abbreviationExpander = (abbr) => {
  const abbreviations = {
    BH: 'backhand',
    FH: 'forehand'
  }

  const words = abbr.split(' ')

  const expandedWords = words.map(word => abbreviations[word] || word.toLowerCase())

  return capitalize(expandedWords.join(' '))
}

function shotFromLeftSide (shot) {
  const startY = shot.resulting_ball_movement.trajectory.start.location.y
  const endY = shot.resulting_ball_movement.trajectory.end.location.y
  const startX = shot.resulting_ball_movement.trajectory.start.location.x

  return (startY < endY && startX < 10) || (startY >= endY && startX < 10)
}

const renderLabel = (label, speedText) => {
  return <Tooltip title={<>{abbreviationExpander(label)}{speedText}</>}>{label}{speedText}</Tooltip>
}

export const getShotLabel = (shot, shotIdx) => {
  if (!shot?.resulting_ball_movement) return renderLabel('Unknown')

  const isVolley = shot?.is_volley ? ' Volley' : ''
  const isOverhead = shot?.vertical_type === 'overhead' ? 'Overhead ' : ''
  const sideOfCourt = shot?.side_of_court ? capitalize(shot.side_of_court) : shotFromLeftSide(shot) ? 'Left' : 'Right'
  const shotType = shot?.shot_type || 'unknown'

  let speedText = ''
  if (shot?.resulting_ball_movement.speed) {
    speedText = <span className='shot-speed'>{Math.round(shot.resulting_ball_movement.speed)}mph</span>
  }
  const showSpeed = shotType === 'drive'

  // Handle with different shot ids
  switch (shotIdx) {
    case 0:
      return <>Serve{speedText}</>
    case 1:
      return <>Return{speedText}</>
    case 2:
      return <>3rd shot {capitalize(shotType)}{showSpeed ? speedText : ''}</>
    default:
      break
  }

  // Construct label if it's depends on shot type
  if (shotType === 'tweener') return renderLabel('Tweener', speedText)

  if (shotType === 'smash') return renderLabel(`${isOverhead}Smash`, speedText)

  // Construct label if it's depends on array of shot types
  if (['atp', 'erne'].includes(shotType)) {
    return renderLabel(`${sideOfCourt.toUpperCase()}-side ${shotType}`, speedText)
  }

  if (['dink', 'drop', 'drive', 'lob'].includes(shotType)) {
    return renderLabel(`${isVolley} ${capitalize(shotType)}`, showSpeed ? speedText : '')
  }

  return renderLabel('Unknown')
}
