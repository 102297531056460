import { Box, styled } from '@mui/material'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import QRCodeIcon from '@/assets/icons/qr-code-icon.svg?react'
import { Button } from '@/components/button'
import CopyCodeButton from '@/components/button/copy-code'
import { Divider } from '@/components/divider'
import QRCodeComponent from '@/components/qr-code/QRCodeComponent'
import { Spinner } from '@/components/spinner'
import useMobileDetect from '@/hooks/use-mobile-detect'
import useZendesk from '@/hooks/use-zendesk'
import { selectLoggedInUser } from '@/store/auth'
import { isProd } from '@/utils'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

export default function Ambassador () {
  const [referralLink, setReferralLink] = useState()
  const isDesktop = useMobileDetect('desktop')
  const qrRef = useRef()

  const user = useSelector(selectLoggedInUser)

  const { toggleZendeskWidget } = useZendesk()

  function handleDownloadQR () {
    if (qrRef.current.downloadQRCode) {
      qrRef.current.downloadQRCode()
    }
  }

  useEffect(() => {
    if (user.referrals?.myCode) {
      const url = isProd ? 'https://pb.vision' : 'https://pbv-dev.web.app'
      const referralLink = `${url}/?rf=${user.referrals.myCode.toUpperCase()}`
      setReferralLink(referralLink)
    }
  }, [user])

  return (
    <Container className={clsx([{ mobile: !isDesktop }])}>
      <h2>Ambassador Program</h2>
      <Divider />
      {user.referrals
        ? (
          <>
            <Box className='referral-information'>
              <div className='title'>Your Unique Referral code</div>
              <div className='code-content'>
                <CopyCodeButton code={referralLink} placeholder={user.referrals?.myCode} />
                <Button className='button' onClick={() => toggleZendeskWidget()}>Request a custom code</Button>
              </div>
              <div className='lifetime-rewards'>
                <div className='title'>Lifetime referrals earned</div>
                <div className='credits'>
                  <h3>{user.referrals ? user.referrals.numDriven : <Spinner text='Loading...' />} New Subscribers</h3>
                </div>
              </div>
            </Box>
            <Divider />
            <div className='how-to-get-rewards'>
              <h3>Rewards</h3>
              <p className='body-md'>
                When a friend signs up and becomes a subscriber, both you and your friend will receive <b>15 Video Credits</b>.
              </p>
              <h3>Two ways to earn rewards</h3>

              <h4>Option 1: Share your link with your QR code</h4>
              <p className='body-md'>
                Players can visit your link by typing in the URL or simply by scanning the code below!
              </p>
              <div className='qr-code-container'>
                <QRCodeComponent ref={qrRef} value={referralLink} downloadedFileName={`pbv-${user.referrals.myCode.toUpperCase()}.png`} />
              </div>
              <p className='body-md'>
                <span style={{ color: COLORS['primary-500'] }}>Pro-tip:</span> Download your QR code to your phone for easy access
              </p>
              <Button className='gray' style={{ alignSelf: 'flex-start' }} onClick={handleDownloadQR}>Download QR Code <QRCodeIcon /></Button>

              <h4>Option 2: Tag your friends</h4>
              <p className='body-md'>
                Tag a friend in your video and we&apos;ll send them a link to the video which includes your referral code.
              </p>
              <div className='gif-container'>
                <img
                  className='gif'
                  src='https://storage.googleapis.com/pbv-pro/assets/tagging-demo.gif'
                  alt='tagging demo gif'
                />
              </div>
            </div>
          </>
          )
        : <Spinner text='Loading...' />}
    </Container>
  )
}

const Container = styled('div')({
  ...column,
  alignItems: 'flex-start',
  gap: '8px',
  '& .referral-information': {
    flex: 1,
    width: '100%',
    padding: '24px',
    marginTop: '24px',
    marginBottom: '24px',
    background: COLORS['neutral-100'],
    border: `1px solid ${COLORS['neutral-300']}`,
    '& .title': {
      fontSize: '16px',
      fontWeight: 600,
      color: COLORS['neutral-800']
    },
    '& .code-content': {
      ...row,
      cursor: 'pointer',
      gap: '16px',
      '& .button': {
        background: 'transparent'
      }
    },
    '& .lifetime-rewards': {
      marginTop: '24px',
      '& .credits': {
        ...row,
        gap: '4px',
        alignItems: 'center'
      },
      '& .icon': {
        color: COLORS['primary-500']
      }
    }
  },
  '& h4': {
    marginTop: '12px'
  },
  '& .how-to-get-rewards': {
    ...column,
    gap: '12px',
    marginTop: '24px',
    '& .gif-container': {
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    },
    '.gif': {
      width: 'auto',
      height: 'auto',
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  '& .qr-code-container': {
    display: 'flex',
    justifyContent: 'center',
    padding: 22,
    alignItems: 'center',
    backgroundColor: COLORS['neutral-100']
  },
  '&.mobile': {
    '& .code-content': {
      ...column,
      '& .code': {
        justifyContent: 'center',
        flex: 0
      }
    }
  }
})
