import { styled } from '@mui/material/styles'
import { useParams } from 'react-router-dom'

import { PlayerImage } from '../player-image'

import useSafeNavigate from '@/hooks/use-safe-navigate'
import { useURLParams } from '@/hooks/use-url-params'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import theme from '@/utils/theme'

export const PLAYERS_SX = {
  '& .players': {
    ...column,
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    '& > p': {
      color: COLORS['neutral-500'],
      marginBottom: theme.spacing(1)
    },
    '& .player': {
      ...row,
      width: '100%',
      paddingRight: theme.spacing(1),
      borderRadius: theme.shape.borderRadiusSmall,
      overflow: 'hidden',
      backgroundColor: COLORS.white,
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      '&:hover, &.active': {
        backgroundColor: COLORS['neutral-500'],
        '& p': {
          color: COLORS.white
        }
      },
      '& .name': {
        flex: 1,
        paddingLeft: theme.spacing(2),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      },
      '& .taggable, & .tagged': {
        '& .player-name': {
          border: 'none',
          borderRadius: theme.shape.borderRadiusSmall,
          height: 30,
          background: 'transparent',
          width: '30px !important',
          padding: 4,
          '&:hover': {
            backgroundColor: COLORS['neutral-200']
          }
        }
      }
    }
  }
}

const Container = styled('div')({
  '& .title': {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '18px',
    color: COLORS['neutral-500'],
    marginBottom: '16px'
  },
  ...PLAYERS_SX
})

export function Players ({ hasError, getPlayerName, aiEngineVersion }) {
  const { vid } = useParams()
  const navigate = useSafeNavigate()
  const { nestParamsIntoURL } = useURLParams()

  const handlePlayerClick = (playerId) => {
    if (hasError) return
    const newTabURL = `/video/${vid}/player/${playerId}`
    navigate(nestParamsIntoURL(newTabURL))
  }

  return (
    <Container>
      <p className='title'>Player Stats</p>
      <div className='players'>
        {Array.from({ length: 4 }).map((_, id) => (
          <div className='player' key={id} onClick={() => handlePlayerClick(id + 1)}>
            <PlayerImage playerIdx={id} numberOfImages={1} width={40} height={40} imageWidth={100} previewOnly />
            <p className='name body-sm'>{getPlayerName(id)}</p>
          </div>
        ))}
      </div>
    </Container>
  )
}
