import { styled } from '@mui/material'
import React from 'react'

import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { row } from '@/utils/flexGrid'

export default function MultiWayToggle (props) {
  const { options, onChange, selected, stats, multi = false, toggle = false } = props

  function isSelected (cur) {
    if (multi) {
      return selected.includes(cur)
    }
    return cur === selected
  }

  function handleChange (update) {
    if (multi) {
      return selected.includes(update) ? onChange(selected.filter((v) => v !== update)) : onChange([...selected, update])
    } else if (toggle) {
      return selected === update ? onChange(null) : onChange(update)
    }
    onChange(update)
  }

  return (
    <Container>
      {options.map((option, i) => (
        <div onClick={() => handleChange(option.value)} className={cls('option', isSelected(option.value) && 'selected')} key={i}>
          <span>{option.label}</span>
          {stats && <div className='badge'>{stats[option.value]}</div>}
        </div>
      ))}
    </Container>
  )
}

const Container = styled('div')({
  ...row,
  backgroundColor: COLORS['neutral-200'],
  borderRadius: '8px',
  overflow: 'hidden',
  '& .option': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    padding: '8px',
    fontSize: '12px',
    transition: '300ms',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS['neutral-400'],
      color: COLORS.white
    },
    '&.selected': {
      backgroundColor: COLORS['neutral-500'],
      color: COLORS.white
    }
  },
  '& .badge': {
    background: '#fff',
    color: '#000',
    borderRadius: '100%',
    minWidth: '1.7em',
    lineHeight: '142%',
    fontSize: 10,
    alignSelf: 'center',
    marginLeft: 8,
    textAlign: 'center',
    padding: 2
  }
})
