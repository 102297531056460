import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import { CircularProgress, styled } from '@mui/material'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { OrthographicCamera, useTexture } from '@react-three/drei'
import { Canvas, extend, useThree, useFrame } from '@react-three/fiber'
import * as Sentry from '@sentry/react'
import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'
import clsx from 'clsx'
import { cubic } from 'maath/easing'
import { MeshLineGeometry, MeshLineMaterial } from 'meshline'
import { useEffect, useMemo, useRef, useCallback, Suspense, useReducer, useState } from 'react'
import { createPortal } from 'react-dom'
// import { VideoTexture, Box3 } from 'three'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector as useAppSelector } from 'react-redux'
import { MathUtils, Matrix4, PerspectiveCamera, SplineCurve, Vector2, Vector3, Color } from 'three'

import { Button } from '../button/index.jsx'
import Scoreboard from '../scoreboard/index.jsx'

import { FadeAnimation } from './FadeAnimation.jsx'
import { createOverlaysFromCv, createRallyFramesByIndex } from './overlay.js'
import { RippleAnimation } from './RippleAnimation.jsx'

import ErrorIcon from '@/assets/error-outline.svg?react'
import MenuToggleIcon from '@/assets/video-overlay/icon-menu-toggle.svg?react'
import lineAlphaMapTextureUrl from '@/assets/video-overlay/line-alpha-map.png'
import xTextureUrl from '@/assets/video-overlay/x-texture.png'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { selectLoggedInUser, updateCurrentUser } from '@/store/auth.js'
import { showPopup } from '@/store/controls.js'
import { useSnackbar } from '@/store/providers/snackbar-provider.jsx'
import { useGetCVData } from '@/store/video.js'
import COLORS from '@/utils/colors'
import { POPUP_KEYS } from '@/utils/popups.js'

extend({ MeshLineGeometry, MeshLineMaterial })

const Container = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  pointerEvents: 'none'
})

const OverlayControls = styled(({ store, className }) => {
  const isMobile = useMobileDetect()
  const openSnackbar = useSnackbar()
  const toggles = useSelector(store, state => state.context.controls.toggles)
  const dispatch = useDispatch()
  const isScoreboardDataAvailable = useSelector(store, state => state.context.isScoreboardDataAvailable)
  const initialToggles = useRef(calculateTogglesBitmask(toggles))
  const [hasChanges, setHasChanges] = useState(false)

  function calculateTogglesBitmask () {
    let bitmask = 0

    bitmask += toggles.ball ? 1 << 0 : 0 << 0
    bitmask += toggles.players ? 1 << 1 : 0 << 1
    bitmask += toggles.bounces ? 1 << 2 : 0 << 2
    bitmask += toggles.net ? 1 << 3 : 0 << 3
    bitmask += toggles.shots ? 1 << 4 : 0 << 4
    bitmask += toggles.court ? 1 << 5 : 0 << 5
    bitmask += toggles.time ? 1 << 6 : 0 << 6
    bitmask += toggles.scoreboard ? 1 << 7 : 0 << 7

    return bitmask
  }

  async function applyToggledStateToAllVideos () {
    try {
      const payload = {
        settings: {
          overlays: calculateTogglesBitmask()
        }
      }
      await dispatch(updateCurrentUser(payload))
      openSnackbar('Successfully applied.')
    } catch (error) {
      openSnackbar('Failed.')
    }
  }

  useEffect(() => {
    setHasChanges(calculateTogglesBitmask(toggles) !== initialToggles.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggles])

  return (
    <div className={clsx([className, { mobile: isMobile }])}>
      <div className='toggles'>
        <div className='toggles__checkboxes'>
          <div className='toggles__column'>
            <FormControlLabel
              control={
                <Checkbox checked={toggles.ball} onChange={() => store.send({ type: 'toggle: ball' })} />
              }
              label='Ball'
            />
            <FormControlLabel
              control={
                <Checkbox checked={toggles.players} onChange={() => store.send({ type: 'toggle: players' })} />
              }
              label='Players'
            />
            <FormControlLabel
              control={
                <Checkbox checked={toggles.bounces} onChange={() => store.send({ type: 'toggle: bounces' })} />
              }
              label='Bounces'
            />
            <FormControlLabel
              control={
                <Checkbox checked={toggles.net} onChange={() => store.send({ type: 'toggle: net' })} />
              }
              label='Net Impact'
            />
            <FormControlLabel
              control={
                <Checkbox checked={toggles.shots} onChange={() => store.send({ type: 'toggle: shots' })} />
              }
              label='Shots'
            />
          </div>
          <div className='toggles__column'>
            <FormControlLabel
              control={
                <Checkbox checked={toggles.court} onChange={() => store.send({ type: 'toggle: court' })} />
              }
              label='Court Outline'
            />
            <FormControlLabel
              control={
                <Checkbox checked={toggles.time} onChange={() => store.send({ type: 'toggle: time' })} />
              }
              label='Time &amp; Frames'
            />
            <FormControlLabel
              disabled={!isScoreboardDataAvailable}
              onClick={(e) => !isScoreboardDataAvailable && dispatch(showPopup(POPUP_KEYS.SCOREBOARD_NOT_AVAILABLE))}
              control={
                <Checkbox checked={toggles.scoreboard} onChange={() => store.send({ type: 'toggle: scoreboard' })} />
              }
              label='Scoreboard'
            />
            {/* <FormControlLabel
              control={
                <Checkbox checked={toggles.rally} onChange={() => store.send({ type: 'toggle: rally' })} />
              }
              label='Rally #'
            /> */}
          </div>
        </div>
        <Button onClick={applyToggledStateToAllVideos} className='toggles__apply green' disabled={!hasChanges}>Save for all videos</Button>
      </div>
    </div>
  )
})({
  width: '320px',
  height: 'auto',

  margin: 30,
  marginLeft: 'auto',

  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  padding: '3px 0 16px 0',
  borderRadius: '12px',

  pointerEvents: 'auto',

  position: 'relative',
  zIndex: 10,

  alignSelf: 'flex-start',

  color: COLORS['neutral-700'],

  '& .toggles': {
    flexDirection: 'row'
  },

  '& .toggles__checkboxes': {
    display: 'flex',
    marginLeft: '1rem'
  },
  '& .toggles__apply': {
    marginTop: '1rem',
    marginLeft: '1rem'
  },

  '& .toggles__column': {
    display: 'flex',
    flexDirection: 'column'
  },

  '& .MuiTypography-root': {
    fontSize: '14px'
  },
  '& .MuiCheckbox-root': {
    padding: '6px'
  },

  '&.mobile': {
    position: 'relative',
    display: 'flex',

    maxWidth: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignSelf: 'center',
    padding: '6px 0 12px 0',

    '& .toggles__checkboxes': {
      justifyContent: 'space-between'
    },

    '& .MuiTypography-root': {
      fontSize: '12px'
    },
    '& .MuiCheckbox-root': {
      padding: '6px',
      '& .MuiSvgIcon-root': {
        width: 12,
        height: 12
      }
    }
  }
})

const PANEL_TRANSITION = 'bottom 0.05s ease-in'

function selectArePlayerControlsHidden (state) {
  return !state.context.player.hasPlayed || (state.context.player.playing && state.context.player.userInactive)
}

const TimePanel = styled(({ store, className }) => {
  const timeInMsecs = useSelector(store, state => Math.round(state.context.time * 1000))
  const frameIndex = useSelector(store, state => state.context.frameIndex)

  const arePlayerControlsHidden = useSelector(store, selectArePlayerControlsHidden)
  const bottom = arePlayerControlsHidden ? 13 : 56

  return (
    <div className={className} style={{ bottom }}>
      <div>
        <div>
          MS: {timeInMsecs}
        </div>
        <div>
          Frame: {frameIndex + 1}
        </div>
      </div>
    </div>
  )
})({
  position: 'absolute',
  left: 13,
  zIndex: 10,
  minWidth: 100,
  transition: PANEL_TRANSITION,
  '& > div': {
    padding: '7px 7px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '8px',
    color: COLORS.white,
    fontWeight: 600,
    fontSize: 12,
    lineHeight: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 5
  }
})

// TODO: Remove if we won't be using Rally # as part of video overlay
// const RallyPanel = styled(({ store, className }) => {
//   const rallyNumber = useSelector(store, state => state.context.rallyNumber)

//   const arePlayerControlsHidden = useSelector(store, selectArePlayerControlsHidden)
//   const bottom = arePlayerControlsHidden ? 13 : 56

//   return (
//     <div className={className} style={{ bottom }}>
//       <div>
//         Rally #{rallyNumber}
//       </div>
//     </div>
//   )
// })({
//   position: 'absolute',
//   right: 16,
//   zIndex: 10,
//   transition: PANEL_TRANSITION,
//   '& > div': {
//     padding: '7px 10px',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     borderRadius: '8px',
//     color: COLORS.white,
//     fontWeight: 600,
//     fontSize: 18,
//     lineHeight: 1
//   }
// })

const MenuButton = styled('button')({
  '--icon-color': COLORS.white,
  position: 'absolute',
  top: 0,
  right: 0,
  width: 32,
  height: 32,
  margin: '7px 8px',
  backgroundColor: COLORS['primary-500'],
  border: `1px solid ${COLORS['neutral-300']}`,
  borderRadius: 8,
  pointerEvents: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 11
})

const LoadingIndicator = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 32,
  height: 32,
  margin: '7px 8px',
  backgroundColor: COLORS['neutral-700'],
  border: `1px solid ${COLORS['neutral-500']}`,
  borderRadius: 8,
  pointerEvents: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 11
})

const ErrorIndicator = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 32,
  height: 32,
  margin: '7px 8px',
  backgroundColor: COLORS.error,
  border: `1px solid ${COLORS['danger-200']}`,
  borderRadius: 8,
  pointerEvents: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 11
})

const Panels = styled(({ store, muxPlayerRef, className }) => {
  const ref = useRef()
  const ready = useSelector(store, state => state.context.ready)

  const time = useSelector(store, state => state.context.controls.toggles.time)
  const scoreboard = useSelector(store, state => state.context.controls.toggles.scoreboard)
  // const rally = useSelector(store, state => state.context.controls.toggles.rally)
  const controlsVisible = useSelector(store, state => state.context.controlsVisible)
  const isScoreboardDataAvailable = useSelector(store, state => state.context.isScoreboardDataAvailable)

  return (
    <div ref={ref} className={className}>
      {
        ready
          ? (
            <>
              {scoreboard && isScoreboardDataAvailable && <Scoreboard muxPlayerRef={muxPlayerRef} />}
              {time && <TimePanel store={store} />}
              {/* {rally && <RallyPanel store={store} />} */}
              <ClickAwayListener
                mouseEvent='onMouseDown'
                touchEvent='onTouchStart'
                onClickAway={() => {
                  store.send({ type: 'hide controls' })
                }}
              >
                <Box sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                >
                  <MenuButton
                    type='button'
                    onPointerDown={() => {
                      store.send({ type: 'toggle controls visibility' })
                    }}
                  >
                    <MenuToggleIcon style={{ width: 20 }} />
                  </MenuButton>
                  {controlsVisible && <OverlayControls store={store} />}
                </Box>
              </ClickAwayListener>
            </>
            )
          : (
            <LoadingIndicator
              title='Loading Overlays …'
            >
              <CircularProgress
                size={16}
                variant='indeterminate'
              />
            </LoadingIndicator>
            )
      }
    </div>
  )
})({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

function * findInWindow ({ framesByIndex, frameIndex, window }) {
  for (let f = 0; f < window; f++) {
    const frame = framesByIndex[frameIndex - f]
    yield { framesSince: f, frame, frameIndex }
  }
}

const BOUNCE_WINDOW = 16
const selectRecentBounces = createRecentEventSelector('bounce', BOUNCE_WINDOW)

function Bounces ({ store, scale }) {
  const animations = useSelector(store, selectRecentBounces)

  const rippleScale = useMemo(() => scale.clone()
    .multiplyScalar(2 * 50)
    .multiply(new Vector3(1, 0.2, 1)), [scale])

  return (
    animations.map(({ action, getFrameIndex }, n) => (
      <RippleAnimation
        key={`bounce-ripple-${n}`}
        position={[action.u, action.v * -1, 0]}
        scale={rippleScale}
        getFrameIndex={getFrameIndex}
        ringColor='#E16A2C'
      />
    ))
  )
}

function createRecentEventSelector (eventName, window) {
  return (state) => {
    const { frameIndex, framesByIndex } = state.context

    const recents = []
    for (const { framesSince, frame } of findInWindow({
      framesByIndex,
      frameIndex,
      window
    })) {
      if (frame?.balls?.selected === eventName) {
        recents.push({
          framesSince,
          action: frame.actions[eventName],
          getFrameIndex: () => framesSince
        })
      }
    }

    return recents
  }
}

const SHOT_WINDOW = 16
const selectRecentShots = createRecentEventSelector('shot', SHOT_WINDOW)

function Shots ({ store, scale }) {
  const animations = useSelector(store, selectRecentShots)

  const rippleScale = useMemo(() => scale.clone()
    .multiplyScalar(2 * 28)
    .multiply(new Vector3(1, 1, 1)), [scale])

  return (
    animations.map(({ action, getFrameIndex }, n) => (
      <RippleAnimation
        key={`shot-ripple-${n}`}
        position={[action.u, action.v * -1, 0]}
        scale={rippleScale}
        getFrameIndex={getFrameIndex}
        speed={0.5}
        ringColor='yellow'
      />
    ))
  )
}

const NET_WINDOW = 60
const selectRecentNets = createRecentEventSelector('net', NET_WINDOW)

function Nets ({ store, scale }) {
  const spriteTexture = useTexture(xTextureUrl)
  const animations = useSelector(store, selectRecentNets)

  const animScale = useMemo(() => scale.clone().multiply(new Vector3(16 * 2, 16 * 2, 1)), [scale])

  return (
    animations.map(({ action, getFrameIndex }, n) => (
      <FadeAnimation
        key={`net-animation-${n}`}
        position={[action.u, action.v * -1, 0]}
        scale={animScale}
        getFrameIndex={getFrameIndex}
        map={spriteTexture}
        duration={NET_WINDOW}
      />
    ))
  )
}

function createFirstFrameByRally (rallies) {
  return rallies.map(rally => rally.frame_index)
}

function selectIndexOfLastUnfinishedRally (state) {
  const { frameIndex: curr, firstFramesByRally } = state.context
  return firstFramesByRally.findLastIndex(next => curr > next - 1)
}

function selectFirstFrameOfCurrentRally (state) {
  const { framesByIndex, firstFramesByRally } = state.context
  const rallyIndex = selectIndexOfLastUnfinishedRally(state)
  return framesByIndex[firstFramesByRally[rallyIndex]]
}

// convert XYZ to UV
function xyzToUv (camera, xyz) {
  const uv = new Vector3(xyz.x, xyz.y, xyz.z)
  uv.project(camera)
  uv.x = (0.5 + uv.x / 2) * 1
  uv.y = (0.5 - uv.y / 2) * 1
  return uv
}

// convert to meshline points
function uvToPoints (uv) {
  return [uv.x, uv.y * -1]
}

function CourtOutline ({ store, scale }) {
  const courtPointsFrame = useSelector(store, selectFirstFrameOfCurrentRally)
  const source = courtPointsFrame?.court?.court_points

  if (!(source && source.filter(Boolean).length === 12)) return null

  const uvs = [
    // far left
    [source[0].u, source[0].v * -1],
    // far center
    [source[1].u, source[1].v * -1],
    // far right
    [source[2].u, source[2].v * -1],
    // near right
    [source[11].u, source[11].v * -1],
    // near center
    [source[10].u, source[10].v * -1],
    // near left
    [source[9].u, source[9].v * -1],
    // far left
    [source[0].u, source[0].v * -1]
  ]

  return (
    <mesh>
      <meshLineGeometry points={uvs} />
      <meshLineMaterial
        lineWidth={scale.y * 3 * 2}
        color='#00D1FF'
        transparent
        opacity={0.8}
      />
    </mesh>
  )
}

function NetOutline ({ store, scale }) {
  const camera = useSelector(store, state => state.context.camera)

  // Net Outline XYZ
  // via https://usapickleball.org/docs/USA-Pickleball-Official-Rulebook-2024-v1.pdf
  //   (diagram page 11, text on page 13 in section 2.C.)
  //
  // - net posts are 1ft from each sideline
  // - net is 36" high at the sideline, 34" in the middle
  //
  const uvs = [
    // Post Top (Left)
    { x: 0 - 1, y: 36 / 12, z: 22 },
    // Net Middle
    { x: 10, y: 34 / 12, z: 22 },
    // Post Top (Right)
    { x: 20 + 1, y: 36 / 12, z: 22 },
    // Post Bottom (Right)
    { x: 20 + 1, y: 0, z: 22 },
    // Post Bottom (Left)
    { x: 0 - 1, y: 0, z: 22 },
    // Post Top (Left)
    { x: 0 - 1, y: 36 / 12, z: 22 }
  ].map((xyz) => xyzToUv(camera, xyz))

  return (
    <mesh>
      <meshLineGeometry points={uvs.map(uvToPoints)} />
      <meshLineMaterial
        lineWidth={scale.y * 3 * 2}
        color='#00D1FF'
        transparent
        opacity={0.8}
      />
    </mesh>
  )
}

// This function can be used to display an outline of the court
// from the point of view of the perspective camera
/*
function CameraDiagnostic ({ store, scale }) {
  const camera = useSelector(store, state => state.context.camera)

  const uvs = [
    { x: 0, y: 0, z: 0 },

    { x: 20, y: 0, z: 0 },
    { x: 20, y: 0, z: 44 },
    { x: 0, y: 0, z: 44 },

    { x: 0, y: 0, z: 0 }
  ].map((xyz) => xyzToUv(camera, xyz))

  return (
    <mesh>
      <meshLineGeometry points={uvs.map(uvToPoints)} />
      <meshLineMaterial
        lineWidth={scale.y * 3 * 2}
        color='#f00'
        transparent
        opacity={1}
      />
    </mesh>
  )
}
*/

const colorsByIndex = ['#1130F5', '#5FCEFA', '#F6C944', '#ED6B2E']

function generateCirclePoints (radius, segments) {
  const points = []
  for (let i = 0; i < segments; i++) {
    const theta = (i / segments) * 2 * Math.PI
    const x = radius * Math.cos(theta)
    const y = 0
    const z = radius * Math.sin(theta)
    points.push(new Vector3(x, y, z))
  }
  return points
}

function Player ({ store, index, scale }) {
  const ref = useRef()
  const playerColor = useMemo(() => new Color(colorsByIndex[index]), [index])
  const camera = useSelector(store, state => state.context.camera)
  const framesByIndex = useSelector(store, state => state.context.framesByIndex)

  const origin = useMemo(() => new Vector3(), [])
  const _v = useMemo(() => new Vector3(), [])

  useFrame(() => {
    const { frameIndex } = store.getSnapshot().context

    const data = framesByIndex[frameIndex]

    const posUvc = data?.court?.player_points?.[index]
    const pos = data?.player_court_positions?.[index]

    if (pos) {
      const x = pos.x
      const y = 0
      const z = pos.y

      origin.set(x, y, z)
      origin.project(camera)
      origin.x = (0.5 + origin.x / 2) * 1
      origin.y = (0.5 - origin.y / 2) * 1

      const coords = generateCirclePoints(
        2, // radius in ft
        16 // segments
      )
      // translate to position on court
      for (const coord of coords) {
        coord.x += pos.x
        coord.z += pos.y
      }
      const uvs = [...coords, coords[0]].map((coord) => {
        _v.copy(coord).project(camera)
        _v.x = (0.5 + _v.x / 2) * 1
        _v.y = (0.5 - _v.y / 2) * 1
        return _v.clone()
      })
      // subtract position on court
      for (const uv of uvs) {
        uv.x -= origin.x
        uv.y -= origin.y
      }
      for (const uv of uvs) {
        uv.y *= -1
      }
      const curve = new SplineCurve(uvs)
      const points = curve.getSpacedPoints(uvs.length * 4)

      // Set Player Circle to position from XYZ data
      // ref.current.position.x = origin.x
      // ref.current.position.y = origin.y * -1

      // Set Player Circle to position from UV data
      ref.current.position.x = posUvc.u
      ref.current.position.y = posUvc.v * -1

      ref.current.geometry.setPoints(points)
      ref.current.material.opacity = posUvc.confidence * 0.8
      ref.current.visible = true
    } else {
      ref.current.visible = false
    }
  })

  return (
    <mesh ref={ref}>
      <meshLineGeometry />
      <meshLineMaterial
        sizeAttenuation={1}
        lineWidth={scale.x * 8}
        transparent
        toneMapped={false}
        color={playerColor}
      />
    </mesh>
  )
}

// TODO rounded edges
// TODO scale control
function TrajectoryLine ({ store, getPoints }) {
  const ref = useRef()

  const lineAlphaMapTexture = useTexture(lineAlphaMapTextureUrl)

  const widthCallback = useCallback((p) => Math.max(cubic.out(p), 0.1), [])

  useFrame(() => {
    if (ref.current) {
      const { frameIndex } = store.getSnapshot().context

      const vectors = getPoints(frameIndex)

      // TODO draw a single dot if vectors.length === 1

      if (vectors.length >= 2) {
        const curve = new SplineCurve(vectors)
        const points = curve.getSpacedPoints(vectors.length * 8)

        ref.current.geometry.setPoints(points, widthCallback)
        ref.current.visible = true
      } else {
        ref.current.visible = false
      }
    }
  })

  return (
    <mesh ref={ref}>
      <meshLineGeometry />
      <meshLineMaterial
        alphaMap={lineAlphaMapTexture}
        useAlphaMap={1}
        //
        sizeAttenuation={1}
        lineWidth={0.075 / 4}
        //
        color='#61CF3F'
        transparent
        toneMapped={false}
        //
        // depthTest={false}
        // depthWrite={false}
        // opacity={0.5}
        // blending={AdditiveBlending}
      />
    </mesh>
  )
}

// TODO should use Snapshot XYZ if available
const USABLE_ACTIONS = ['bounce', 'shot', 'net', 'ball']
function getBallFrameUvc (frame) {
  const THRESHOLD = 0.7

  // if a Snapshot selection is present,
  // prefer the Action Model Output which matches it
  // if confidence meets threshold
  if (
    frame?.balls?.selected === 'bounce' &&
    frame?.actions?.bounce &&
    frame?.actions?.bounce.confidence >= THRESHOLD
  ) {
    return frame?.actions?.bounce
  }
  if (
    frame?.balls?.selected === 'shot' &&
    frame?.actions?.shot &&
    frame?.actions?.shot.confidence >= THRESHOLD
  ) {
    return frame?.actions?.shot
  }
  if (
    frame?.balls?.selected === 'net' &&
    frame?.actions?.net &&
    frame?.actions?.net.confidence >= THRESHOLD
  ) {
    return frame?.actions?.net
  }

  // otherwise use the ball position, if confidence meets threshold
  if (frame?.actions?.ball?.confidence >= THRESHOLD) {
    return frame?.actions?.ball
  }

  // if no better option available,
  // sort Action Model Outputs by Frame UVC confidence,
  // and return highest UVC meeting confidence threshold
  if (frame?.actions && Object.keys(frame.actions).length) {
    const match = Object.entries(frame.actions)
      .filter(([k]) => USABLE_ACTIONS.includes(k))
      .sort(([k1, a], [k2, b]) => b.confidence - a.confidence)
    if (match.length) {
      const uvc = match[0][1]
      if (uvc.confidence >= THRESHOLD) {
        return uvc
      }
    }
  }

  return null
}

function Trail ({ store, scale }) {
  const TRAIL_WINDOW = 15

  const getPoints = useCallback((frameIndex) => {
    const { framesByIndex } = store.getSnapshot().context

    const prev = []
    for (let i = frameIndex - TRAIL_WINDOW + 1; i <= frameIndex; i++) {
      if (framesByIndex[i]) {
        prev.push(framesByIndex[i])
      }
    }

    const uvcs = prev.map(getBallFrameUvc).filter(Boolean)
    if (!uvcs.length) return []

    const uvs = uvcs.map(({ u, v }) => [u, v])
    return uvs.map(([u, v]) => new Vector2(u, v * -1))
  }, [store])

  return (
    <TrajectoryLine store={store} getPoints={getPoints} scale={scale} />
  )
}

function Overlay ({ store }) {
  const toggles = useSelector(store, state => state.context.controls.toggles)
  const size = useThree(state => state.size)

  const scale = useMemo(() => {
    const w = size.width
    const h = size.height
    const ar = w / h
    const ph = 1 / h
    const pw = ph / ar

    return new Vector3(pw, ph, 0)
  }, [size])

  const playerObjects = useMemo(() => Array.from({ length: 4 }), [])

  return (
    <>
      {toggles.court && <CourtOutline store={store} scale={scale} />}
      {toggles.court && <NetOutline store={store} scale={scale} />}

      {toggles.net && <Nets store={store} scale={scale} />}
      {toggles.bounces && <Bounces store={store} scale={scale} />}
      {toggles.shots && <Shots store={store} scale={scale} />}

      {toggles.ball && <Trail store={store} scale={scale} />}

      {toggles.players && playerObjects.map((_, n) => (
        <Player key={`player-${n}`} store={store} index={n} scale={scale} />
      ))}
    </>
  )
}

/* Avoid throwing errors when @react-three/fiber ThreeElement properties aren't found */
/* eslint react/no-unknown-property: "off" */
function Scene ({ store, player }) {
  const viewport = useThree(state => state.viewport)
  const size = useThree(state => state.size)
  const ready = useSelector(store, state => state.context.ready)
  const cameraSettings = useSelector(store, state => state.context.cameraSettings)

  const dimensions = useMemo(() => {
    const aspect = player.media.nativeEl.videoWidth / player.media.nativeEl.videoHeight
    if (viewport.width / viewport.height > aspect) {
      return { width: viewport.height * aspect, height: viewport.height }
    } else {
      return { width: viewport.width, height: viewport.width / aspect }
    }
  }, [viewport, player.media.nativeEl])

  // when `size` and `cameraSettings` are both available, create the camera
  useEffect(() => {
    if (!cameraSettings) return

    store.send({
      type: 'setup camera',
      ...cameraSettings,
      aspect: size.width / size.height
    })
  }, [store, size.width, size.height, cameraSettings])

  // Uncomment this to render video frames as textures
  //
  // const gl = useThree(state => state.gl)
  // const texture = useMemo(() => {
  //   const texture = new VideoTexture(player.media.nativeEl)
  //   texture.colorSpace = gl.outputColorSpace
  //   return texture
  // }, [player, gl.outputColorSpace])

  return (
    <>
      <group
        position={[-dimensions.width / 2, dimensions.height / 2, 0]}
        scale={[dimensions.width, dimensions.height, 1]}
      >
        {ready && <Overlay store={store} />}
      </group>

      {/* Uncommment this for video texture */}
      {/*
      <mesh>
        <planeGeometry args={[viewport.width, viewport.height]} />
        <meshBasicMaterial map={texture} toneMapped={false} />
      </mesh>
       */}
    </>
  )
}

function createMemento (snapshot) {
  return {
    controls: {
      toggles: snapshot.context.controls.toggles,
      settings: snapshot.context.controls.settings
    }
  }
}

function persist (snapshot) {
  window.localStorage.setItem('pbv-video-overlay', JSON.stringify(createMemento(snapshot)))
}

function restore () {
  return JSON.parse(window.localStorage.getItem('pbv-video-overlay'))
}

/*
 * The `createCamera` function creates a Three.js PerspectiveCamera given CV file camera settings
 *
 * It has to translate between coordinate systems:
 *
 * Three.js is right-handed, with positive Y up.
 *
 * Internally, the CV coordinate system is right-handed, with negative Z up.
 * When written to the CV JSON file, the Z position value of a camera or object is inverted (multiplied by -1) to positive Z.
 * However, the camera orientation values are not changed.
 *
 * This function is very confusing as currently written and could be refactored in the future.
 */
function createCamera ({ fov, position, orientation }, { aspect }) {
  const matrix = new Matrix4(
    1, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0, 1
  )

  const camera = new PerspectiveCamera(
    // convert horizontal fov (cv) to vertical fov (three.js)
    Math.atan(Math.tan(MathUtils.radToDeg(fov) * Math.PI / 360) / aspect) * 360 / Math.PI,
    aspect,
    0.1,
    1000
  )

  camera.position.set(
    position.x,
    position.y,
    position.z
  )

  camera.rotation.set(
    0,
    -MathUtils.degToRad(90),
    -MathUtils.degToRad(90)
  )

  camera.applyMatrix4(matrix)
  camera.scale.x = 1

  camera.rotation.order = 'YXZ'
  camera.rotation.y += -orientation.yaw
  camera.rotation.x += orientation.pitch
  camera.rotation.z += orientation.roll

  camera.updateMatrixWorld()
  camera.updateProjectionMatrix()

  return camera
}

function _VideoOverlay ({
  muxPlayerRef,
  rallyNumber,
  insights,
  vid
}) {
  const store = useMemo(() => createStore({
    errors: [],

    frameRate: 0,
    cameraSettings: null,
    camera: null,

    time: 0,
    frameIndex: 0,
    framesByIndex: {},
    firstFramesByRally: [],

    controlsVisible: false,
    isScoreboardDataAvailable: false,
    controls: {
      toggles: {
        ball: false,
        players: true,
        court: false,
        bounces: true,
        net: true,
        shots: true,
        time: false,
        rally: true,
        scoreboard: false
      },
      settings: {
        video: true
      }
    },

    player: {
      hasPlayed: false,
      playing: undefined,
      over: undefined,
      userInactive: true,
      hasLoadedMetadata: false
    },
    changed: false,
    ready: false
  }, {
    'received data': (context, event) => ({
      frameRate: event.frameRate,
      firstFramesByRally: event.firstFramesByRally,
      framesByIndex: event.framesByIndex,
      cameraSettings: event.cameraSettings,

      // update immediately based on current frameRate
      frameIndex: Math.round(context.time * event.frameRate)
    }),
    'video time': (context, event) => ({
      time: event.mediaTime
    }),
    'video frame': (context, event) => ({
      time: event.mediaTime,
      frameIndex: Math.round(event.mediaTime * context.frameRate)
    }),

    'toggle controls visibility': {
      controlsVisible: (context, event) => !context.controlsVisible
    },
    'hide controls': {
      controlsVisible: (context, event) => false
    },
    'toggle: all': (context, event) => {
      const all = !Object.values(context.controls.toggles).includes(false)
      return {
        controls: {
          ...context.controls,
          toggles: Object.fromEntries(Object.keys(context.controls.toggles).map(key => [key, !all]))
        },
        changed: true
      }
    },
    'toggle: ball': {
      controls: (context, event) => ({
        ...context.controls,
        toggles: {
          ...context.controls.toggles,
          ball: !context.controls.toggles.ball
        }
      }),
      changed: true
    },
    'toggle: players': {
      controls: (context, event) => ({
        ...context.controls,
        toggles: {
          ...context.controls.toggles,
          players: !context.controls.toggles.players
        }
      }),
      changed: true
    },
    'toggle: court': {
      controls: (context, event) => ({
        ...context.controls,
        toggles: {
          ...context.controls.toggles,
          court: !context.controls.toggles.court
        }
      }),
      changed: true
    },
    'toggle: bounces': {
      controls: (context, event) => ({
        ...context.controls,
        toggles: {
          ...context.controls.toggles,
          bounces: !context.controls.toggles.bounces
        }
      }),
      changed: true
    },
    'toggle: net': {
      controls: (context, event) => ({
        ...context.controls,
        toggles: {
          ...context.controls.toggles,
          net: !context.controls.toggles.net
        }
      }),
      changed: true
    },
    'toggle: shots': {
      controls: (context, event) => ({
        ...context.controls,
        toggles: {
          ...context.controls.toggles,
          shots: !context.controls.toggles.shots
        }
      }),
      changed: true
    },
    'toggle: time': {
      controls: (context, event) => ({
        ...context.controls,
        toggles: {
          ...context.controls.toggles,
          time: !context.controls.toggles.time
        }
      }),
      changed: true
    },
    'toggle: scoreboard': {
      controls: (context, event) => ({
        ...context.controls,
        toggles: {
          ...context.controls.toggles,
          scoreboard: !context.controls.toggles.scoreboard
        }
      }),
      changed: true
    },
    'toggle: scoreboard update': {
      controls: (context, event) => ({
        ...context.controls,
        toggles: {
          ...context.controls.toggles,
          scoreboard: event.value
        }
      }),
      changed: true
    },
    'set isScoreboardDataAvailable': {
      isScoreboardDataAvailable: (context, event) => event.value
    },
    'toggle: rally': {
      controls: (context, event) => ({
        ...context.controls,
        toggles: {
          ...context.controls.toggles,
          rally: !context.controls.toggles.rally
        }
      }),
      changed: true
    },
    'toggle: bitmask update': {
      controls: (context, event) => {
        const bitmask = event.bitmask
        return {
          ...context.controls,
          toggles: {
            ...context.controls.toggles,
            ball: (bitmask & (1 << 0)) !== 0,
            players: (bitmask & (1 << 1)) !== 0,
            bounces: (bitmask & (1 << 2)) !== 0,
            net: (bitmask & (1 << 3)) !== 0,
            shots: (bitmask & (1 << 4)) !== 0,
            court: (bitmask & (1 << 5)) !== 0,
            time: (bitmask & (1 << 6)) !== 0,
            scoreboard: (bitmask & (1 << 7)) !== 0
          }
        }
      },
      changed: true
    },
    'toggle settings: video': {
      controls: (context, event) => ({
        ...context.controls,
        settings: {
          ...context.controls.settings,
          video: !context.controls.settings.video
        }
      }),
      changed: true
    },
    'set rally number': {
      rallyNumber: (context, event) => event.rallyNumber
    },
    'setup camera': {
      camera: (context, event) => createCamera(context.cameraSettings, { aspect: event.aspect })
    },

    'mux player: play': {
      player: (context, event) => ({ ...context.player, playing: true, hasPlayed: true })
    },
    'mux player: pause': {
      player: (context, event) => ({ ...context.player, playing: false })
    },
    'mux player: pointerover': {
      player: (context, event) => ({ ...context.player, over: true })
    },
    'mux player: pointerout': {
      player: (context, event) => ({ ...context.player, over: false })
    },
    'mux player: userinactivechange': {
      player: (context, event) => ({ ...context.player, userInactive: event.detail })
    },
    'mux player: loadedmetadata': {
      player: (context, event) => ({ ...context.player, hasLoadedMetadata: true })
    },

    'received snapshot': {
      controls: (context, event) => ({
        ...context.controls,
        toggles: {
          ...context.controls.toggles,
          ...event.snapshot.controls.toggles
        },
        settings: {
          ...context.controls.settings,
          ...event.snapshot.controls.settings
        }
      })
    },

    error: {
      errors: (context, event) => ([...context.errors, event.error])
    },

    'saved changes': {
      changed: false
    },

    ready: (context, event) => ({
      ready: true
    })
  }), [])

  const cameraSettings = useSelector(store, store => store.context.cameraSettings)
  const camera = useSelector(store, store => store.context.camera)
  const ready = useSelector(store, store => store.context.ready)
  const user = useAppSelector(selectLoggedInUser)
  const hasLoadedMetadata = useSelector(store, store => store.context.player.hasLoadedMetadata)
  const [errors, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'error': {
        return [
          ...state,
          action.error
        ]
      }
    }
  }, [])

  useEffect(() => {
    function persistIfChanged (snapshot) {
      if (snapshot.context.changed) {
        persist(snapshot)
        store.send({ type: 'saved changes' })
      }
    }
    const { unsubscribe } = store.subscribe(persistIfChanged)
    return unsubscribe
  }, [store])

  useEffect(() => {
    const snapshot = restore()
    if (snapshot) {
      store.send({ type: 'received snapshot', snapshot })
    }
  }, [store])

  useEffect(() => {
    store.send({ type: 'set rally number', rallyNumber })
  }, [store, rallyNumber])

  useEffect(() => {
    const muxPlayerEl = muxPlayerRef.current

    function handler (event) {
      store.send({ type: `mux player: ${event.type}`, detail: event.detail })
    }

    if (muxPlayerEl) {
      const video = muxPlayerEl.media.nativeEl

      const callback = (now, metadata) => {
        const { mediaTime } = metadata
        store.send({ type: 'video frame', mediaTime })
        video.requestVideoFrameCallback(callback)
      }
      video.requestVideoFrameCallback(callback)

      // before video starts playing, and before frame rate data is available
      // we only know the video currentTime
      store.send({ type: 'video time', mediaTime: video.currentTime })

      // Uncomment this if using VideoTexture
      // video.style.visibility = 'hidden'

      // https://github.com/muxinc/elements/blob/main/packages/mux-player/REFERENCE.md#events
      // https://media-chrome.mux.dev/examples/vanilla/state-change-events-demo.html
      muxPlayerEl?.addEventListener('play', handler)
      muxPlayerEl?.addEventListener('pause', handler)
      muxPlayerEl?.addEventListener('pointerover', handler)
      muxPlayerEl?.addEventListener('pointerout', handler)
      muxPlayerEl?.addEventListener('userinactivechange', handler)
      muxPlayerEl?.addEventListener('loadedmetadata', handler)
    }
    return () => {
      muxPlayerEl?.removeEventListener('play', handler)
      muxPlayerEl?.removeEventListener('pause', handler)
      muxPlayerEl?.removeEventListener('pointerover', handler)
      muxPlayerEl?.removeEventListener('pointerout', handler)
      muxPlayerEl?.removeEventListener('userinactivechange', handler)
      muxPlayerEl?.removeEventListener('loadedmetadata', handler)
    }
  }, [store, muxPlayerRef])

  // TODO
  // fetch the smaller overlay data from the server instead of the larger CV data
  // would require a server endpoint which runs the `createOverlaysFromCv` server-side
  const cvData = useGetCVData({ vid })
  // console.log({ cvData })

  useEffect(() => {
    if (!cvData) return

    const data = createOverlaysFromCv(cvData)

    store.send({
      type: 'received data',
      frameRate: data.camera.fps,
      firstFramesByRally: createFirstFrameByRally(data.rallies),
      framesByIndex: createRallyFramesByIndex(data.rallies),
      cameraSettings: data.camera
    })
  }, [cvData, store])

  useEffect(() => {
    if (cvData && cameraSettings && camera && !ready) {
      store.send({ type: 'ready' })
    }
  }, [store, cvData, cameraSettings, camera, ready])

  useEffect(() => {
    const overlays = user?.settings?.overlays

    if (overlays) {
      store.send({ type: 'toggle: bitmask update', bitmask: overlays })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const isScoreboardDataAvailable = insights && insights.rallies[0]?.running_score?.length > 0

    if (isScoreboardDataAvailable) {
      store.send({ type: 'set isScoreboardDataAvailable', value: true })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insights?.game_data])

  if (errors.length) {
    return <VideoOverlayErrorBoundaryFallback error={errors[0]} />
  }

  return (
    <Container>
      <Panels store={store} muxPlayerRef={muxPlayerRef} />
      {muxPlayerRef.current?.media.nativeEl &&
        createPortal(
          <ErrorBoundary
            fallbackRender={() => null}
            onError={(error, info) => {
              // Catch and handle WebGL context creation errors
              if (
                error.message.includes('WebGL context could not be created') ||
                error.message.includes('Error creating WebGL context') ||
                // null is not an object (evaluating 't.getShaderPrecisionFormat(t.VERTEX_SHADER,t.HIGH_FLOAT).precision')
                error.message.includes('getShaderPrecisionFormat')
              ) {
                dispatch({ type: 'error', error })
                return
              }

              // Throw any unexpected errors, reporting to Sentry
              throw error
            }}
          >
            <Canvas
              style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}
            >
              <OrthographicCamera makeDefault position={[0, 0, 1]} />
              {
                muxPlayerRef.current?.media.nativeEl && hasLoadedMetadata && (
                  <ErrorBoundary
                    fallbackRender={() => null}
                    onError={(error, info) => {
                      // Catch and handle common errors (e.g.: network issues where image does not load) without reporting to Sentry
                      if (error.message.match(/Could not load (.+)\.(png|svg)/)) {
                        dispatch({ type: 'error', error })
                        return
                      }

                      // Throw any unexpected errors, reporting to Sentry
                      throw error
                    }}
                  >
                    <Suspense fallback={null}>
                      <Scene
                        store={store}
                        player={muxPlayerRef.current}
                      />
                    </Suspense>
                  </ErrorBoundary>
                )
              }
            </Canvas>
          </ErrorBoundary>,
          muxPlayerRef.current.media.nativeEl.parentNode
        )}
    </Container>
  )
}

function VideoOverlayErrorBoundaryFallback ({ error }) {
  useEffect(() => {
    console.error(error)
  }, [error])

  return (
    <ErrorIndicator
      title='Sorry! An error occurred while rendering the Video Overlay'
    >
      <ErrorIcon style={{ width: '75%', height: '75%' }} />
    </ErrorIndicator>
  )
}

const VideoOverlay = Sentry.withErrorBoundary(
  _VideoOverlay, {
    fallback: (props) => <VideoOverlayErrorBoundaryFallback {...props} />
  }
)

export default VideoOverlay
