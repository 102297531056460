import { styled } from '@mui/material/styles'

import HelpIcon from '@/assets/help.svg?react'
import { SimpleButton } from '@/components/button/simple-button'
import { Tooltip } from '@/components/tooltip'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { row } from '@/utils/flexGrid'

const Section = styled('section')({
  display: 'flex',
  '& .row': {
    ...row
  },
  '& .half': {
    flex: 1,
    '& h6': {
      margin: '0 0 10px'
    }
  },
  '& .line': {
    width: 1,
    background: COLORS['neutral-200'],
    margin: '0 10px'
  },
  '& button': {
    textTransform: 'none',
    flex: 1,
    padding: '10px 0',
    borderRadius: 10,
    '&.green': {
      color: COLORS['primary-700'],
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },
    '&.red': {
      color: COLORS['danger-700'],
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    },
    '&.selected': {
      background: COLORS['neutral-300']
    },
    '& div': {
      fontSize: 12,
      marginTop: 4,
      color: COLORS['neutral-700']
    }
  }
})

const KitchenSegment = (props) => {
  const { updateFilter, filters, show, rallyKitchenStats } = props
  if (!show) return null
  const kitchenArrival = filters?.kitchenArrival || []

  const update = (evt) => {
    const arrival = Number(evt.currentTarget.dataset.arrival)
    const update = kitchenArrival.includes(arrival) ? kitchenArrival.filter((a) => a !== arrival) : [...kitchenArrival, arrival]
    updateFilter('kitchenArrival', update)
  }

  return (
    <>
      <div className='title'>Kitchen Arrival<Tooltip title='View shots where selected player made or did not make it to the kitchen.'><HelpIcon /></Tooltip></div>
      <Section>
        <div className='half'>
          <h6>Serving Side <span>({rallyKitchenStats.servingYes + rallyKitchenStats.servingNo})</span></h6>
          <div className='row'>
            <SimpleButton className={cls('green', kitchenArrival.includes(1) && 'selected')} onClick={update} data-arrival={1}>Made It<div>({rallyKitchenStats.servingYes})</div></SimpleButton>
            <SimpleButton className={cls('red', kitchenArrival.includes(2) && 'selected')} onClick={update} data-arrival={2}>Didn&apos;t make&nbsp;it<div>({rallyKitchenStats.servingNo})</div></SimpleButton>
          </div>
        </div>
        <div className='line' />
        <div className='half'>
          <h6>Receiving Side <span>({rallyKitchenStats.receivingYes + rallyKitchenStats.receivingNo})</span></h6>
          <div className='row'>
            <SimpleButton className={cls('green', kitchenArrival.includes(3) && 'selected')} onClick={update} data-arrival={3}>Made It<div>({rallyKitchenStats.receivingYes})</div></SimpleButton>
            <SimpleButton className={cls('red', kitchenArrival.includes(4) && 'selected')} onClick={update} data-arrival={4}>Didn&apos;t make&nbsp;it<div>({rallyKitchenStats.receivingNo})</div></SimpleButton>
          </div>
        </div>
      </Section>
    </>
  )
}

export default KitchenSegment
