// This slice stores temporary data for player tagging received by native app contact popup
import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

export const sliceName = 'playerTags'

const tagSlice = createSlice({
  name: sliceName,
  initialState: {
    tag: null
  },
  reducers: {
    addTag: (state, action) => {
      Object.assign(state, { tag: action.payload })
    },
    clearTag: (state, action) => {
      Object.assign(state, { tag: null })
    }
  }
})

export const selectLastTag = state => state[sliceName].tag
export const useLastTag = () => useSelector(selectLastTag)
export const { reducer } = tagSlice
export const { addTag, clearTag } = tagSlice.actions
