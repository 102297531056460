import { useEffect, useRef } from 'react'
import { useSearchParams, useLocation, useMatch } from 'react-router-dom'

export function useClearLoop () {
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()
  const isExplorer = useMatch('/video/:vid/explore')
  const prevPageIsExplorer = useRef(null)

  useEffect(() => {
    // When location changes from /explore to any other page, clear the search params
    if (prevPageIsExplorer.current && !isExplorer) {
      setSearchParams(
        (params) => {
          params.delete('t')
          params.delete('l')
          return params
        },
        { replace: true }
      )
    }
    prevPageIsExplorer.current = isExplorer
  }, [location, isExplorer, setSearchParams])
}
