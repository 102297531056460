import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { DEMO_VIDEOS } from '../anonymous-upload'

import Page from './index'

function Redirect ({ vid }) {
  const { tab, tabId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    let subpath = tab ? `/${tab}` : ''
    if (tab && tabId) subpath += `/${tabId}`

    navigate(`/video/${vid}${subpath}`, { replace: true })
  }, [navigate, tab, tabId, vid])
}

export default [
  {
    path: '/video/:vid/:tab?/:tabId?',
    element: <Page />
  },
  {
    path: '/video/tvgz3pqij0ll/:tab?/:tabId?',
    element: <Redirect vid={DEMO_VIDEOS[0].vid} />
  }
].concat(
  // special links for demo videos (first video will always use `/videos/demo` path )
  DEMO_VIDEOS.map((v, idx) => ({
    path: `/video/${idx ? v.name.toLowerCase().replaceAll(' ', '-') : 'demo'}`,
    element: <Redirect vid={v.vid} />
  }))
)
