import DeleteIcon from '@mui/icons-material/Delete'
import LinkIcon from '@mui/icons-material/Link'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PlayArrow from '@mui/icons-material/PlayCircleOutline'
import { ListItemIcon, Menu, MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import ConfirmationDialog from '../dialog/ConfirmationDialog'
import { SkeletonComponent } from '../skeleton'

import Whatshot from '@/assets/icons/whatshot.svg?react'
import { Button } from '@/components/button'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useURLParams } from '@/hooks/use-url-params'
import { useSnackbar } from '@/store/providers/snackbar-provider'
import { setVideoField } from '@/store/video'
import { isProd } from '@/utils'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { copyToClipboard } from '@/utils/helper'
import { isInMobileAppWebview, notifyShareRequested } from '@/utils/mobile-app-communication'
import theme from '@/utils/theme'

const Wrapper = styled('div')({
  position: 'relative',
  backgroundColor: 'transparent !important',
  width: '100%',
  borderRadius: '0px !important',
  '& .title-lg': {
    textAlign: 'left',
    marginBottom: '8px'
  },
  '& .highlight-buttons': {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1),
    width: '100%'
  },
  '& .buttons': {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1),
    width: '100%',
    maxWidth: '600px'
  },
  '& .combined-button': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minWidth: 0,
    border: `1px solid ${COLORS['neutral-300']}`
  },
  '& .highlight-button': {
    flex: 1,
    minWidth: 0,
    height: 50,
    paddingInline: theme.spacing(1),
    borderRadius: 0,
    border: '0px',
    overflow: 'hidden',
    '& div': {
      flex: 1,
      minWidth: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textAlign: 'left',
      marginLeft: theme.spacing(1)
    }
  },
  '& .highlight-menu-button': {
    height: 50,
    minWidth: '40px',
    borderRadius: 0,
    padding: '2px 2px',
    border: '0px',
    '&:hover': {
      borderLeftColor: COLORS['neutral-300']
    },
    '& svg': {
      marginLeft: 0,
      color: `${COLORS['neutral-700']} !important`
    }
  },
  [theme.breakpoints.down('lg')]: {
    '& .title-lg': {
      textAlign: 'center',
      marginBottom: '8px'
    },
    '& .highlight-buttons': {
      width: '100%',
      gridTemplateColumns: 'repeat(2, 1fr) !important'
    }
  }
})

function usePickHighlights (insights, numToPick, userData) {
  return useMemo(() => {
    let highlights = []
    if (userData?.highlights) {
      highlights = insights?.highlights.filter(insight => userData?.highlights?.some(single => single.s === insight.s))
    } else {
      highlights = insights?.highlights
    }

    // Filter out the sequence highlights as they have
    // a bit of a different structure, so won't show them
    // until we develop a proper way to display them
    const filteredHighlights = highlights?.filter(highlight => highlight?.kind !== 'sequence')

    const sortedHighlights = [...(filteredHighlights ?? [])].map(x => ({
      ...x,
      isLongestRallyHighlight: isLongestRallyHighlight(x, insights)
    }))
    sortedHighlights.sort((a, b) => {
      // longest rally comes first
      if (a.isLongestRallyHighlight) {
        return -1
      }
      if (b.isLongestRallyHighlight) {
        return 1
      }
      // then sort by score (highest first)
      if (a.score !== b.score) {
        return b.score - a.score // score DESC
      }
      // tiebreak on start time, end time and finally kind
      if (a.s !== b.s) {
        return a.s - b.s
      }
      if (a.e !== b.e) {
        return a.e - b.e
      }
      return a.kind.localeCompare(b.kind)
    })
    const picked = []
    const usedTimeRanges = new Set()
    const pickedByType = {}
    for (const highlight of sortedHighlights) {
      const timeRange = `${highlight.s}:${highlight.e}`
      if (!usedTimeRanges.has(timeRange)) {
        usedTimeRanges.add(timeRange)
        if (!pickedByType[highlight.kind]) {
          pickedByType[highlight.kind] = []
        }
        const previouslyPicked = pickedByType[highlight.kind]
        const augmentedHighlight = {
          ...highlight,
          name: highlightName(highlight, previouslyPicked, insights),
          isLongestRallyHighlight: isLongestRallyHighlight(highlight, insights)
        }
        pickedByType[highlight.kind].push(augmentedHighlight)
        picked.push(augmentedHighlight)
        if (picked.length === numToPick) {
          break
        }
      }
    }
    return picked
  }, [insights, numToPick, userData])
}

function isLongestRallyHighlight (highlight, insights) {
  return (
    highlight.kind === 'long_rally' &&
    insights.game_data.longest_rally.rally_idx === highlight.rally_idx)
}

function highlightName (highlight, previouslyPicked, insights) {
  const num = previouslyPicked.length + 1
  const capitalizedName = {
    atp: 'ATP',
    hands_battle: 'Firefight',
    long_rally: 'Long Rally'
  }[highlight.kind] ?? `${highlight.kind[0].toLocaleUpperCase()}${highlight.kind.substring(1)}`
  return `${capitalizedName} #${num}`
}

export function Highlights ({ userData, insights, onHighlightClicked, videoExcerpt, className, workflow }) {
  const params = useParams()
  const { vid } = params
  const dispatch = useDispatch()
  const isMobile = useMobileDetect()
  const { nestCustomParamsIntoURL } = useURLParams()
  const openSnackbar = useSnackbar()
  const pickedHighlights = usePickHighlights(insights, 10, userData)
  const isWorkflowDone = Boolean(workflow?.epochFinished)

  const [deleteDialogOpened, setIsDeleteDialogOpened] = useState(false)
  const [highForDelete, setHighForDelete] = useState()

  const [menuState, setMenuState] = useState({
    isOpen: false,
    anchorEl: null,
    highlightIdx: null
  })

  const openMenu = (event, idx) => {
    setMenuState({
      isOpen: true,
      anchorEl: event.currentTarget,
      highlightIdx: idx
    })
  }

  const closeMenu = () => {
    setMenuState({
      isOpen: false,
      anchorEl: null,
      highlightIdx: null
    })
  }

  const onShareHighlight = async (id) => {
    let shareURL = ''
    shareURL = isProd ? 'https://share.pb.vision' : await import.meta.env.VITE_API_SERVER
    shareURL += `/video/share/${vid}`

    const paramsArray = [
      {
        key: 't',
        value: pickedHighlights[id].s / 1000
      },
      {
        key: 'l',
        value: (pickedHighlights[id].e - pickedHighlights[id].s) / 1000
      }
    ]

    if (isInMobileAppWebview()) {
      notifyShareRequested(nestCustomParamsIntoURL(shareURL, paramsArray))
    } else {
      copyToClipboard(nestCustomParamsIntoURL(shareURL, paramsArray))
        .then(() => {
          openSnackbar('Link copied to clipboard')
        })
        .catch(() => {
          openSnackbar('Failed to copy то clipboard', 'error')
        })
    }

    closeMenu()
  }

  const onDeleteHighlight = useCallback(async () => {
    // Format highlights from the insights until endpoint for edit be ready to accept additional params (kind & rally_idx)
    function formatHighlights (highlights) {
      return highlights.map(highlight => {
        const { s, e } = highlight
        return {
          type: 'string',
          s,
          e
        }
      })
    }
    try {
      const highlights = userData?.highlights || formatHighlights(insights.highlights)

      const newHighlightsArray = highlights.filter(single => single.s !== highForDelete)

      await dispatch(setVideoField(vid, 'highlights', newHighlightsArray))
      openSnackbar('Highlight removed')
      setHighForDelete()
    } catch (error) {
      openSnackbar('Failed to remove highlight', 'error')
    } finally {
      closeMenu()
    }
  }, [dispatch, highForDelete, insights?.highlights, openSnackbar, userData?.highlights, vid])

  const onOpenDeleteDialog = (forDelete) => {
    setIsDeleteDialogOpened(true)
    setHighForDelete(forDelete)
  }

  if (workflow && !isWorkflowDone) {
    return (
      <SkeletonComponent rootClassName='video-sidebar-column' />
    )
  }

  if (!pickedHighlights.length) {
    return null
  }

  return (
    <Wrapper className={cls('highlights', isMobile && 'mobile', className)}>
      <p className='title-lg'>Highlights</p>
      <div className='highlight-buttons'>
        {pickedHighlights.map((highlight, highlightIdx) => (
          <div className='combined-button' key={highlightIdx}>
            <Button
              className={cls('gray', 'highlight-button', 'combined')}
              variant='outlined'
              color='primary'
              onClick={() => onHighlightClicked(highlight)}
            >
              {highlight.isLongestRallyHighlight ? <Whatshot /> : <PlayArrow style={{ color: COLORS['neutral-400'] }} />}
              <div>
                {highlight.name}
              </div>
            </Button>
            <Button
              variant='outlined'
              color='primary'
              className='gray highlight-menu-button'
              aria-controls={menuState.isOpen && menuState.highlightIdx === highlightIdx ? `menu-${highlightIdx}` : undefined}
              aria-expanded={menuState.isOpen && menuState.highlightIdx === highlightIdx ? 'true' : undefined}
              onClick={(e) => openMenu(e, highlightIdx)}
              onMouseEnter={(e) => e.stopPropagation()}
            >
              <MoreVertIcon />
            </Button>
            <Menu
              anchorEl={menuState.anchorEl}
              open={menuState.isOpen && menuState.highlightIdx === highlightIdx}
              onClose={closeMenu}
            >
              <MenuItem onClick={() => onShareHighlight(highlightIdx)}>
                <ListItemIcon>
                  <LinkIcon fontSize='small' />
                </ListItemIcon>
                Share a link
              </MenuItem>
              {Boolean(videoExcerpt) && (
                <MenuItem onClick={() => onOpenDeleteDialog(highlight.s)}>
                  <ListItemIcon>
                    <DeleteIcon fontSize='small' />
                  </ListItemIcon>
                  Remove highlight
                </MenuItem>
              )}
            </Menu>
          </div>
        ))}
      </div>
      <ConfirmationDialog isOpen={deleteDialogOpened} onAccept={onDeleteHighlight} closeDialog={() => setIsDeleteDialogOpened(false)} agreeText='Delete Highlight' title='Are you sure?' confirmButtonClasses='green' disagreeButtonClasses='gray' />
    </Wrapper>
  )
}
