import { asArray } from './array'
import { pickAndNormalize } from './pick'

export const initialFilters = {
  customTags: [],
  characteristics: [],
  directions: [],
  highlights: [],
  players: [0, 1, 2, 3],
  strokeSide: null,
  sequences: [],
  serveDepth: [],
  quality: { min: 0, max: 1 },
  types: [],
  errors: [],
  groundStrokeOrVolley: null,
  strokeType: null,
  shotWindow: { numBefore: -1, numAfter: 1 },
  ralliesWon: null,
  kitchenArrival: []
}

const qualityProps = ['min', 'max']
const qualityTypes = { min: 'float', max: 'float' }
const qualityType = (obj) => pickAndNormalize(obj, qualityProps, initialFilters.quality, {}, qualityTypes)

const shotWindowProps = ['numBefore', 'numAfter']
const shotWindowTypes = { numBefore: 'int', numAfter: 'int' }
const shotWindowType = (obj) => pickAndNormalize(obj, shotWindowProps, initialFilters.quality, {}, shotWindowTypes)

const filterTypes = {
  customTags: (val) => asArray(val),
  characteristics: (val) => asArray(val).map(String),
  directions: (val) => asArray(val).map(String),
  highlights: (val) => asArray(val),
  players: (val) => asArray(val).map(Number),
  kitchenArrival: (val) => asArray(val).map(Number),
  strokeSide: (val) => String(val),
  serveDepth: (val) => asArray(val).map(String),
  sequences: (val) => asArray(val).map(String),
  quality: (val) => qualityType(val),
  types: (val) => asArray(val).map(String),
  errors: (val) => asArray(val).map(String),
  groundStrokeOrVolley: (val) => String(val),
  strokeType: (val) => String(val),
  shotWindow: (val) => shotWindowType(val),
  ralliesWon: (val) => String(val)
}

const availableFilters = Object.keys(initialFilters)

export const normalizeFilters = (filters) => pickAndNormalize(filters, availableFilters, {}, filterTypes)
