import { styled } from '@mui/material'
import React from 'react'

import { BarChart } from '@/components/bar-chart'
import COLORS from '@/utils/colors'
import theme from '@/utils/theme'

export default function ShotDistributionCharts ({ playerData }) {
  if (!playerData) return null
  return (
    <Container>
      <div className='single-chart'>
        <p className='body-sm'>Shot Distribution</p>
        <BarChart values={[{ value: playerData[0].total_team_shot_percentage, fillColor: COLORS.player1 }]} emptySpaceColor={COLORS.player2} />
      </div>
      <div className='single-chart'>
        <p className='body-sm'>Shot Distribution</p>
        <BarChart values={[{ value: playerData[2].total_team_shot_percentage, fillColor: COLORS.player3 }]} emptySpaceColor={COLORS.player4} />
      </div>
      <div className='single-chart'>
        <p className='body-sm'>Left side</p>
        <BarChart values={[
          { value: playerData[0]?.left_side_percentage, fillColor: COLORS.player1 },
          { value: playerData[1]?.left_side_percentage, fillColor: COLORS.player2 }
        ]}
        />
      </div>
      <div className='single-chart'>
        <p className='body-sm'>Left side</p>
        <BarChart values={[
          { value: playerData[2]?.left_side_percentage, fillColor: COLORS.player3 },
          { value: playerData[3]?.left_side_percentage, fillColor: COLORS.player4 }
        ]}
        />
      </div>
    </Container>
  )
}

const Container = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(2),
  margin: 'auto',
  '& .single-chart': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: '80%',
    '& .single-chart': {
      '&:nth-of-type(even)': {
        '& p': {
          textAlign: 'right'
        }
      }
    }

  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    '& .single-chart': {
      '& p': {
        // textAlign: 'le'
      }
    }
  }
})
