import { useSnackbar } from '@mui/base'
import CachedIcon from '@mui/icons-material/Cached'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { getShotLabel } from '../shot-label-generator'

import { SimpleButton } from '@/components/button/simple-button'
import { Tooltip } from '@/components/tooltip'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { copyToClipboard } from '@/utils/helper'
import { getColorForShot } from '@/utils/stats'
import { formatMs } from '@/utils/time'

const initialState = { anchor: null, shot: null, repeat: null }

const MenuItem = styled(MuiMenuItem)({
  '& li': {
    color: COLORS['neutral-700'],
    fontSize: 16
  },
  '& svg': {
    marginRight: 14,
    fontSize: 20
  }
})

const chunkSize = 10
const delay = 0

export default function Rallies (props) {
  const { list, selected, setSelected, playerAvatars, muxPlayerRef, isPlaying, contextBefore = 0, contextAfter = 0 } = props
  const [visibleItems, setVisibleItems] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const openSnackbar = useSnackbar()

  const [state, setState] = useState({ ...initialState })
  const open = Boolean(state?.anchor)
  const hasLooping = searchParams.get('t') && searchParams.get('l')
  const onMount = useRef(false)
  const [index, setIndex] = useState(0)

  const filteredRallies = useMemo(() => {
    return list.filter((rally) => !rally.isContext)
  }, [list])

  const handleClick = (event, shot) => {
    event.stopPropagation()
    setState({ ...state, anchor: event.currentTarget, shot })
    return false
  }

  const handleClose = () => {
    setState({ ...state, anchor: null })
  }

  const startRepeat = (data) => {
    const shot = data || state.shot
    handleSelect(shot, true)
    setState({ ...state, anchor: null, repeat: shot })

    // Get all shots in the same rally
    const shotsInRally = list.filter((r) => r.rallyIdx === shot.rallyIdx)

    // Get the closest valid `shotIdx` for `shotStart`
    const shotStart = shotsInRally
      .filter((r) => r.shotIdx <= shot.shotIdx + contextBefore)
      .reduce((prev, curr) => (prev.shotIdx > curr.shotIdx ? prev : curr), shotsInRally[0])

    // Get the closest valid `shotIdx` for `shotEnd`
    const shotEnd = shotsInRally
      .filter((r) => r.shotIdx >= shot.shotIdx + contextAfter)
      .reduce((prev, curr) => (prev.shotIdx < curr.shotIdx ? prev : curr), shotsInRally[shotsInRally.length - 1])

    setSearchParams({ ...Object.fromEntries(searchParams.entries()), t: shotStart.mStart / 1000, l: (shotEnd.mEnd - shotStart.mStart) / 1000 })
  }

  const stopRepeat = (evt) => {
    evt.stopPropagation()
    setState({ ...state, anchor: null, repeat: null })
    setSearchParams(
      (params) => {
        params.delete('t')
        params.delete('l')
        return params
      },
      { replace: true }
    )
  }

  const copyLink = () => {
    handleClose()
    const url = new URL(window.location.href)
    // When shot window before is set - use that to start playing when user clicks
    const shotStart = list.find((r) => r.rallyIdx === state.shot.rallyIdx && r.shotIdx === Math.max(0, state.shot.shotIdx + contextBefore))
    // Find the shot + contextAfter the shot in the same rally
    const shotEnd = list.find((r) => r.rallyIdx === state.shot.rallyIdx && r.shotIdx === Math.min(list.filter((r) => r.rallyIdx === state.shot.rallyIdx).length - 1, state.shot.shotIdx + contextAfter))
    url.searchParams.set('t', shotStart.mStart / 1000)
    url.searchParams.set('l', (shotEnd.mEnd - shotStart.mStart) / 1000)

    copyToClipboard(url.href)
      .then(() => {
        openSnackbar('Link copied to clipboard')
      })
      .catch(() => {
        openSnackbar('Failed to copy то clipboard', 'error')
      })
  }

  const play = (evt) => {
    evt.stopPropagation()
    muxPlayerRef?.current?.play()
  }

  const handleSelect = (data, forcePlay = false) => {
    const isAlreadySelected =
      selected &&
      selected.rallyIdx === data.rallyIdx &&
      selected.shotIdx === data.shotIdx &&
      selected.ms === data.mStart

    // Update the selection if not already selected
    if (!isAlreadySelected) {
      setSelected({ rallyIdx: data.rallyIdx, shotIdx: data.shotIdx, ms: data.mStart, auto: false })
    }

    if (!selected || isAlreadySelected || forcePlay) {
      // If nothing is selected or shot is already selected, play the video
      muxPlayerRef?.current?.play()
    }
  }

  useEffect(() => {
    if (index >= filteredRallies.length) return
    const timeout = setTimeout(() => {
      setVisibleItems((prev) => [...prev, ...filteredRallies.slice(index, index + chunkSize)])
      setIndex((prev) => prev + chunkSize)
    }, delay)

    return () => clearTimeout(timeout)
  }, [index, filteredRallies])

  useEffect(() => {
    // Reset the visible items and trigger the re-render when the filtered list changes
    setVisibleItems([])
    setIndex(0)
  }, [filteredRallies])

  useEffect(() => {
    if (selected && onMount.current === false) {
      const search = new URLSearchParams(window.location.search)
      if (search.get('t') && search.get('l')) {
        const selectedShot = list.find((r) => r.rallyIdx === selected.rallyIdx && r.shotIdx === selected.shotIdx)
        if (selectedShot) {
          setState((prev) => ({ ...prev, shot: selectedShot, repeat: selectedShot }))
        }
      }
      // Do this only once on render when selected is set
      onMount.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <section className='rallies'>
      {visibleItems.map((data, idx) => (
        <div key={idx}>
          {(idx === 0 || visibleItems[idx - 1].rallyIdx !== data.rallyIdx) && (
            <div className='title'>Rally #{data.rallyIdx + 1}</div>
          )}
          <div
            className={cls(
              'shot',
              `player${data.shot.player_id + 1}`,
              selected && selected.rallyIdx === data.rallyIdx && selected.shotIdx === data.shotIdx && 'selected'
            )}
            onClick={() => state.repeat ? startRepeat(data) : handleSelect(data)}
          >
            {playerAvatars[data.shot.player_id]}
            {(!isPlaying && selected && selected.rallyIdx === data.rallyIdx && selected.shotIdx === data.shotIdx) && (
              <SimpleButton className='play-overlay' onClick={play}>
                <PlayArrowIcon />
              </SimpleButton>
            )}
            {(isPlaying && hasLooping && selected && selected.rallyIdx === data.rallyIdx && selected.shotIdx === data.shotIdx && state.repeat) && (
              <div className='play-overlay'>
                <CachedIcon className='rotate' />
                <StopIcon onClick={stopRepeat} className='stop-overlay' />
              </div>
            )}
            <div className='time'>{formatMs(data.mStart)}</div>
            <Tooltip className='quality' title={`${Math.round(data.shot.quality.overall * 100)}% shot quality`} component='div'>
              <div
                className='dot'
                style={{ backgroundColor: getColorForShot(data.shot) }}
              />
            </Tooltip>
            <div className='name'>{getShotLabel(data.shot, data.shotIdx)}</div>
            <IconButton
              id='shot-menu-button'
              aria-controls={open ? 'shot-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={(evt) => handleClick(evt, data)}
              edge='start'
            >
              <MoreVertIcon />
            </IconButton>
          </div>
        </div>
      ))}
      <Menu
        id='shot-menu'
        anchorEl={state?.anchor}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'shot-menu-button'
        }}
      >
        {state.repeat === state.shot
          ? <MenuItem onClick={stopRepeat}><StopIcon />Stop watching shots on repeat</MenuItem>
          : <MenuItem onClick={() => startRepeat()}><CachedIcon />Watch shot on repeat</MenuItem>}
        <MenuItem onClick={copyLink}><ContentCopyIcon />Copy Link to Shot</MenuItem>
      </Menu>
    </section>
  )
}
