import { styled, useMediaQuery } from '@mui/material'

import AngleLineWithArrowRight from '@/assets/angle-line-with-errow-right.svg?react'
import MobileRecordingExample from '@/assets/images/mobile-recording-example.png'
import LineWithArrowDownMobile from '@/assets/line-with-arrow-down-mobile.svg?react'
import LineWithArrowDown from '@/assets/line-with-arrow-down.svg?react'
import COLORS from '@/utils/colors'
import { column } from '@/utils/flexGrid'

const AutoPlayLoopVideo = () => {
  return (
    <video
      width='100%'
      height='100%'
      controls={false}
      autoPlay
      loop
      muted
      playsInline
    >
      <source src='https://storage.googleapis.com/pbv-pro/home-vid.mp4' type='video/mp4' />
      <source src='https://storage.googleapis.com/pbv-pro/home-vid.webm' type='video/webm' />
    </video>
  )
}

function Desktop () {
  return (
    <DesktopContainer>
      <div className='video-section-content'>
        <LineWithArrowDown className='top-left-arrow' />
        <div className='phone-illustration'>
          <img src={MobileRecordingExample} alt='mobile recording for PV Vision example' />
          <Message className='message'>
            <strong>Simply record, <br />upload, and improve</strong>
          </Message>
        </div>
        <AngleLineWithArrowRight className='angle-arrow-right' />
        <div className='video-example'>
          <AutoPlayLoopVideo />
          <Message className='message'>
            <strong>Active Play Detection (APD) tracks every player and shot, recreating your game in 3D to maximize learning</strong>
          </Message>
        </div>
        <LineWithArrowDown className='bottom-right-arrow' />
      </div>
      <h2>
        Our AI transforms every video into an advanced training tool, with personalized insights to help you improve.
      </h2>
    </DesktopContainer>
  )
}

function MobileAndTablets () {
  return (
    <MobileContainer>
      <LineWithArrowDownMobile className='arrow-down' />
      <img className='phone-illustration' src={MobileRecordingExample} alt='mobile recording for PV Vision example' />
      <Message className='message mobile'>
        <strong>Simply record, <br />upload, and improve</strong>
      </Message>
      <LineWithArrowDownMobile className='arrow-down' />
      <div className='video-example'>
        <AutoPlayLoopVideo />
      </div>
      <Message className='message mobile'>
        <strong>Active Play Detection (APD) tracks every player and shot, recreating your game in 3D to maximize learning</strong>
      </Message>
      <h2>
        Our AI transforms every video into an advanced training tool, with personalized insights to help you improve.
      </h2>
      <LineWithArrowDownMobile className='arrow-down' />
    </MobileContainer>
  )
}

export function VideoLandingSection () {
  const supportedForDesktopVersion = useMediaQuery('(min-width:900px)')

  return supportedForDesktopVersion ? <Desktop /> : <MobileAndTablets />
}

const MobileContainer = styled('div')({
  ...column,
  gap: '10px',
  alignItems: 'center',
  marginBottom: '20px',
  '& .arrow-down': {
    height: '30px'
  },
  '& .phone-illustration': {
    width: '90%',
    height: 'auto'
  },
  '& .video-example': {
    width: '100%',
    aspectRatio: '1.78 / 1',
    '& video': {
      objectFit: 'cover'
    }
  },
  '& h2': {
    fontSize: '24px',
    lineHeight: '24px',
    textAlign: 'center',
    color: COLORS.black,
    padding: '16px 6px'
  }
})

const DesktopContainer = styled('div')({
  '& h2': {
    fontSize: '40px',
    lineHeight: '40px',
    color: COLORS.black,
    padding: '16px 6px',
    marginTop: '60px',
    textAlign: 'center'
  },

  '& .video-section-content': {
    ...column,
    alignItems: 'flex-start',
    aspectRatio: '1.47/1',
    position: 'relative',
    '& .top-left-arrow': {
      position: 'absolute',
      top: '0',
      left: '80px',
      height: '15%',
      zIndex: 2
    },
    '& .bottom-right-arrow': {
      position: 'absolute',
      top: '93%',
      right: '32%',
      height: '15%',
      zIndex: 2
    },
    '& .angle-arrow-right': {
      position: 'absolute',
      top: '55%',
      left: '80px',
      height: '15%',
      width: '25%',
      zIndex: 2
    },
    '& .phone-illustration': {
      position: 'absolute',
      width: '100%',
      top: '18%',
      zIndex: 2,
      '& img': {
        width: '38%',
        height: 'auto'
      },
      '& .message': {
        position: 'absolute',
        bottom: '-28%',
        left: '30px'
      }
    },
    '& .video-example': {
      position: 'absolute',
      aspectRatio: '1.78 / 1',
      width: '65%',
      top: '25%',
      right: '0',
      height: 'auto',
      '& video': {
        objectFit: 'cover'
      },
      '& .message': {
        position: 'absolute',
        width: '80%',
        bottom: '-15%',
        left: '50%',
        transform: 'translateX(-50%)'
      }
    }
  }
})

const Message = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px',
  border: `1px solid ${COLORS['neutral-300']}`,
  backgroundColor: COLORS['warning-050'],
  borderRadius: '10px',
  fontSize: '20px',
  color: COLORS.black,
  lineHeight: '24px',
  '&.mobile': {
    fontSize: '14px',
    lineHeight: '19px',
    padding: '8px 16px',
    marginTop: '-30px',
    zIndex: 2,
    maxWidth: '90%'
  }

})
