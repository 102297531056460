import { useMemo } from 'react'

import { getColorForShot } from '@/utils/stats'

/**
 * @typedef {object} ShotFor3DViewer
 * @property {TimeAndPlace} start where the ball was when it was hit
 * @property {WorldXYZ} peak where the ball was at its highest point (possibly the same as start or end)
 * @property {TimeAndPlace} end where the ball was when it was hit
 * @property {string} color hexadecimal color value indicating quality
 */
/**
 * @typedef {object} {TimeAndPlace}
 * @property {WorldXYZ} location
 * @property {int} ms the number of milliseconds elapsed since the beginning of the game (video)
 */
/**
 * @typedef {object} {WorldXYZ} a position relative to the far-left corner of the court as seen by the camera
 * @property {double} x distance along the court's 20ft width (baseline)
 * @property {double} y distance the court's 44ft length (sideline)
 * @property {double} z feet above the ground (always >= 0)
 */

/**
 * Converts ShotsWithContext to ShotsFor3DViewer.
 *
 * @param {Array<ShotWithContext>} shotsWithContext shots to convert to 3D viewer format
 * @returns {Array<ShotFor3DViewer>} shots in the format needed by the 3D viewer
 */
export function use3DViewerShots (shotsWithContext) {
  return useMemo(() => {
    const ret = []
    for (const shotWithContext of shotsWithContext) {
      const { shot, shotIdx, rallyIdx } = shotWithContext
      if (!shot.resulting_ball_movement) {
        return // only include shots with trajectories
      }
      const trajectory = shot.resulting_ball_movement.trajectory
      ret.push({
        color: getColorForShot(shot),
        start: {
          ms: trajectory.start.ms,
          location: trajectory.start.location
        },
        peak: trajectory.peak,
        end: {
          ms: trajectory.end.ms,
          location: trajectory.end.location
        },
        originalShot: { ...shot, shotIdx, rallyIdx }
      })
    }
    return ret
  }, [shotsWithContext])
}
