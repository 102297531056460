import { styled } from '@mui/material/styles'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import useSafeNavigate from '@/hooks/use-safe-navigate'
import { useURLParams } from '@/hooks/use-url-params'
import { TAB, TABS } from '@/pages/home/sidebar'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

const Container = styled('div')({
  '&.shortcuts': {
    ...column,
    flex: '1',
    width: '100%',
    backgroundColor: COLORS.white,
    marginBottom: '36px',
    borderRadius: '8px',
    gap: '8px',
    padding: '8px 0px',
    '& .tab': {
      ...row,
      padding: '9.5px 16px',
      gap: '16px'
    },
    '& .body': {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '21px'
    },
    svg: {
      fill: COLORS.black
    }
  }
})

const SHORTCUTS = [TAB.EXPLORE, TAB.GAME_STATS, TAB.TEAM_STATS]
const SHORTCUT_TABS = TABS.filter((tab) => SHORTCUTS.includes(tab.id))

export function Shortcuts ({ hasError }) {
  const { vid, tab: activeTab } = useParams()
  const navigate = useSafeNavigate()
  const { nestParamsIntoURL } = useURLParams()

  const goToTab = useCallback((newTabName, replace = false) => {
    const newTabURL = `/video/${vid}/${newTabName || 'overview'}`
    navigate(nestParamsIntoURL(newTabURL), { replace })
  }, [nestParamsIntoURL, navigate, vid])

  function handleTabChange (newTabName) {
    if (hasError) return
    goToTab(newTabName)
  }

  useEffect(() => {
    // If there is an error and some other tab is selected / or if no tab is selected
    // navigate to default 'overview' tab (This will handle both - main tabs and players)
    if ((activeTab !== 'overview' && hasError) || !activeTab) {
      goToTab('overview', true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container className='shortcuts'>
      {SHORTCUT_TABS.map((tab, i) => (
        <div className='tab' key={i} onClick={() => handleTabChange(tab.id)}>
          {tab.Icon}
          <p className='body'>{tab.name}</p>
        </div>
      ))}
    </Container>
  )
}
