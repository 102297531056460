import COLORS from '@/utils/colors'

export const strokeSides = [{ value: 'right', label: 'Right' }, { value: 'left', label: 'Left' }, { value: null, label: 'Both' }]
export const strokeTypes = [{ value: 'forehand', label: 'Forehand' }, { value: 'backhand', label: 'Backhand' }, { value: null, label: 'Both' }]
export const serveDepths = [{ value: 'kitchen', label: 'Kitchen' }, { value: 'short', label: 'Shallow' }, { value: 'mid', label: 'Medium' }, { value: 'deep', label: 'Deep' }]
export const groundStrokeOrVolley = [{ value: 'groundstroke', label: 'Groundstroke' }, { value: 'volley', label: 'Volley' }, { value: null, label: 'Both' }]
export const shotTypes = [{ value: 'smash', label: 'Smash' }, { value: 'lob', label: 'Lob' }, { value: 'dink', label: 'Dink' }, { value: 'drop', label: 'Drop' }, { value: 'drive', label: 'Drive' }, { value: 'atp', label: 'ATP' }, { value: 'erne', label: 'Erne' }, { value: 'tweener', label: 'Tweener' }, { value: 'speedup', label: 'Speedup' }]
export const errorTypes = [{ value: 'net', label: 'Net' }, { value: 'out', label: 'Out' }, { value: 'none', label: 'None' }]
export const rallySequence = [{ value: 'serve', label: 'Serve' }, { value: 'return', label: 'Return' }, { value: '3', label: '3rd' }, { value: '4', label: '4th' }, { value: '5', label: '5th' }, { value: 'final', label: 'Last' }]
export const rallySequenceValues = rallySequence.map((r) => r.value)
export const customTags = ['Ready position', 'Dinking', 'Backhand volley dink']
export const highlights = [{ value: 'long_rally', label: 'Long rally' }, { value: 'atp', label: 'Atp' }, { value: 'erne', label: 'Erne' }]
export const ralliesWon = [{ value: 'won', label: 'Won' }, { value: 'lost', label: 'Lost' }]
export const shotWindowMarks = [...Array(7).keys()].map((k) => {
  const curr = k - 3
  if (curr > 0) {
    return { value: curr, label: `+${curr}` }
  } else if (curr < 0) {
    return { value: curr, label: curr }
  } else {
    return { value: 0, label: 'Shot' }
  }
})

export const players = [...Array(4).keys()]
export const playerColors = {
  '& .player1': { color: COLORS.player1 },
  '& .player2': { color: COLORS.player2 },
  '& .player3': { color: COLORS.player3 },
  '& .player4': { color: COLORS.player4 }
}
export const kitchenArrivalTypes = [
  'servingYes', // 1
  'servingNo', // 2
  'receivingYes', // 3
  'receivingNo' // 4
]
export const allPlayerCombinations = [[0], [1], [0, 1], [2], [3], [2, 3]]
export const kitchenStats = kitchenArrivalTypes.reduce((acc, c) => {
  acc[c] = 0
  return acc
}, {})
export const shotTypeQuantity = shotTypes.concat(rallySequence).concat(errorTypes).reduce((acc, c) => {
  acc[c.value] = 0
  return acc
}, {})

const arrayFilters = ['characteristics', 'customTags', 'kitchenArrival', 'highlights', 'sequences', 'types', 'directions', 'serveDepth', 'errors']
export const diffFilters = (filters) => {
  const diff = {}
  arrayFilters.forEach((prop) => {
    if (filters[prop].length) Object.assign(diff, { [prop]: filters[prop] })
  })
  if (filters.ralliesWon) Object.assign(diff, { ralliesWon: filters.ralliesWon })
  if (filters.strokeType) Object.assign(diff, { strokeType: filters.strokeType })
  if (filters.groundStrokeOrVolley) Object.assign(diff, { groundStrokeOrVolley: filters.groundStrokeOrVolley })
  if (filters.strokeSide !== null) Object.assign(diff, { strokeSide: filters.strokeSide })
  if (filters.quality.min !== 0 || filters.quality.max !== 1) Object.assign(diff, { quality: filters.quality })
  if (filters.shotWindow.numBefore !== -1 || filters.shotWindow.numAfter !== 1) Object.assign(diff, { shotWindow: filters.shotWindow })
  if (!filters.players.length || players.some((p) => !filters.players.includes(p))) Object.assign(diff, { players: filters.players })
  return diff
}

/**
 * Filters out a specified value from an array.
 *
 * @param {Array} [arr=[]] - The array to filter.
 * @param {*} value - The value to filter out from the array.
 * @returns {Array} A new array that does not include the specified value or an empty [] if `arr` parameter is not an array (undefined/null etc.)
 */
export const filterOutValue = (arr = [], value) => Array.isArray(arr) ? arr.filter((v) => v !== value) : []

/**
 * Filters an array based on the inclusion of values from another array.
 *
 * @param {Array} [arr1=[]] - The primary array to filter.
 * @param {Array} [arr2=[]] - The array containing values to check for inclusion.
 * @returns {Array} A new array containing items from arr1 that are also in arr2.
 */
export const includesFilter = (arr1 = [], arr2 = []) => arr1.filter((a1) => arr2.includes(a1.value))

/**
 * Checks if all players in the selection belong to the same team.
 *
 * @param {number[]} selection - An array of player numbers.
 * @returns {boolean} - Returns true if all players are on the same team, otherwise false.
 */
export function isSameTeam (selection) {
  if (selection.length === 0) return false // No players selected
  const team = Math.floor(selection[0] / 2) // Determine team of the first player
  return selection.every(player => Math.floor(player / 2) === team)
}

// Function to check if a player meets a condition
export const playerMeetsCondition = (player, condition, isServing) => {
  const madeIt = player.ms_to_kitchen !== undefined // true if ms_to_kitchen exists

  return (
    (isServing && condition === 1 && madeIt) || // Serving side made it
    (isServing && condition === 2 && !madeIt) || // Serving side didn't make it
    (!isServing && condition === 3 && madeIt) || // Receiving side made it
    (!isServing && condition === 4 && !madeIt) // Receiving side didn't make it
  )
}
