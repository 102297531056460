import { styled } from '@mui/material'
import { useMemo } from 'react'

import { BarChart } from '@/components/bar-chart'
import COLORS from '@/utils/colors'
import theme from '@/utils/theme'

const players = { 0: 0, 1: 0, 2: 0, 3: 0 }

export const SpeedupDistribution = ({ rallies }) => {
  const speedupDistribution = useMemo(() => {
    if (!rallies) return null
    let total = 0
    const speedups = rallies.reduce((acc, rally) => {
      rally.shots.forEach((shot) => {
        if (shot.is_speedup) {
          if (!acc[shot.player_id]) acc[shot.player_id] = 0
          acc[shot.player_id]++
          total++
        }
      })
      return acc
    }, { ...players })

    return Object.keys(speedups).map((playerId) => {
      return { value: speedups[playerId] / total * 100, fillColor: COLORS[`player${Number(playerId) + 1}`] }
    })
  }, [rallies])
  if (!speedupDistribution) return null

  return (
    <Container>
      <p className='body-sm'>Speedups</p>
      <BarChart values={speedupDistribution} />
    </Container>
  )
}

const Container = styled('div')({
  [theme.breakpoints.up('sm')]: {
    maxWidth: '80%',
    margin: 'auto'
  },
  '& p': {
    marginBottom: 8,
    marginTop: 16
  }
})
