import AddIcon from '@mui/icons-material/Add'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'

import { Container } from './components/container'
import { FilterSideBar } from './components/filter-sidebar'
import { CurrentFilters } from './components/filters'
import { NoShotsFound } from './components/no-shots-found'
import Rallies from './components/rallies'
import { allPlayerCombinations, diffFilters, players, rallySequenceValues, shotTypeQuantity } from './helpers'

import { Button } from '@/components/button'
import { PlayerImage } from '@/components/player-image'
import ShotsViewer from '@/components/shots-viewer'
import { SkeletonComponent } from '@/components/skeleton'
import { use3DViewerShots } from '@/hooks/use-shots-for-3d-viewer'
import { useShotsWithContextFromShotExplorerFilter } from '@/hooks/use-shots-for-shot-explorer'
import { clearFilters, setAllFilters, setShotFilters } from '@/store/shot-filter'
import { APIContext } from '@/utils/api'
import pick from '@/utils/pick'
import { normalizeFilters } from '@/utils/shot-filter'

export const ShotViewer = (props) => {
  const { filters, noFilter, portalRef } = props
  const params = useParams()
  const vid = params.vid
  const { muxPlayerRef, insights, video, workflow, setRallyNumber } = useContext(APIContext)
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const [showDrawer, setShowDrawerState] = useState(false)
  const [selected, setSelected] = useState()

  // Used for no-dead-time play hook
  const [isPlaying, setPlaying] = useState(false)

  const sequenceStatsCache = useRef(null)
  const playbackRate = useRef(0.5)
  const hasLooping = searchParams.get('t') && searchParams.get('l')

  const setShowDrawer = useCallback((show) => {
    // When filter sidebar closes, update the query string
    if (!show && showDrawer) {
      // Get the difference between the current filters and the initial filters
      const diff = diffFilters(filters)
      const hasFilters = Object.keys(diff).length
      if (!hasFilters) {
        setSearchParams()
      } else {
        const filtersQuery = JSON.stringify(diff)
        setSearchParams({ filters: filtersQuery })
      }
    }
    setShowDrawerState(show)
  }, [filters, setSearchParams, showDrawer])

  const { aiEngineVersion } = workflow
  const { userData } = video
  const isWorkflowDone = Boolean(workflow?.epochFinished)

  const rallies = useShotsWithContextFromShotExplorerFilter(insights, filters)
  const shotsWithoutContext = useMemo(() => rallies.filter((r) => !r.isContext), [rallies])
  const shotsFor3DViewer = use3DViewerShots(shotsWithoutContext)
  const autoSelectOnQuery = useRef(false)
  const selectFromQuery = useRef(false)
  const winStatCache = useRef([0, 0])
  const filterWinChanged = useRef(false)
  const dataLoaded = Boolean(insights)

  const updateSelected = (update) => {
    if (filters.shotWindow.numBefore) {
      // When shot window before is set - use that to start playing when user clicks
      const requestedShotStartIdx = update.shotIdx + filters.shotWindow.numBefore
      const shotStartIdx = Math.max(0, requestedShotStartIdx)
      const shotStart = rallies.find((r) => r.rallyIdx === update.rallyIdx && r.shotIdx === shotStartIdx)
      // If found jump to that shot
      if (shotStart) {
        // Use shot start or rally start if before the rally start with 1250ms of buffer
        const extraBuffer = Math.min(0, 1000 * requestedShotStartIdx) // an extra 1000ms of buffer per shot window unit requested (only applies if shot window would take us back before the first shot in the rally)
        const startMs = Math.max(shotStart.mStart, shotStart.rStart) - 1250 + extraBuffer
        jumpToRallyMS(startMs, true)
      } else {
        // This should never happen, but just in case
        jumpToRallyMS(update.ms - 1000, true)
      }
    } else {
      jumpToRallyMS(update.ms - 1000, true)
    }
    setSelected(update)
    setRallyNumber(update.rallyIdx)
  }

  const updateFilter = useCallback((type, value) => {
    // When filter changes (other than sequence) clear the sequence cache, it will be recalculated
    // it's used to cache the sequence/errors even if user changes their filters
    // that way the numbers will always show the total number of shots in the sequence, not the filtered ones
    // if (type !== 'sequences' && type !== 'errors') sequenceStatsCache.current = null
    if (type === 'ralliesWon') filterWinChanged.current = true

    // Selecting None in errors should clear the net and out
    if (type === 'errors' && value.includes('none')) {
      if (filters.errors.includes('none')) {
        value = value.filter((v) => v !== 'none')
      } else {
        value = value.filter((v) => v !== 'net' && v !== 'out')
      }
    }

    // Prevent shot types to have Net / Out with In (hidden filter, this shouldn't happen as they are mutually exclusive)
    const inIndex = filters.types.indexOf('in')
    if (type === 'types' && inIndex !== -1 && (value.includes('net') || value.includes('out'))) {
      value.splice(inIndex, 1)
    }
    dispatch(setShotFilters({ type, value }))

    // In case serve was previously selected and serveDepth has some values and serve is removed - clear serveDepth
    if (type === 'sequences' && !value.includes('serve') && filters.sequences.includes('serve') && filters.serveDepth.length) {
      // Clear the cash when clearing serveDepth
      // sequenceStatsCache.current = null
      dispatch(setShotFilters({ type: 'serveDepth', value: [] }))
    }
  }, [dispatch, filters.errors, filters.sequences, filters.serveDepth.length, filters.types])

  const jumpToRallyMS = useCallback((ms, pause) => {
    const muxPlayer = muxPlayerRef.current
    if (muxPlayer) {
      if (pause) {
        const isMuted = muxPlayerRef.current.muted
        if (!isMuted) {
          // Muting the video to prevent sound when jumping to a shot
          muxPlayerRef.current.muted = true
        }
        // Trigger the play in case player hasn't been initialized (showing the big play button)
        muxPlayer.addEventListener('loadeddata', () => {
          // Since Chrome 50, a play() call on an a <video> or <audio> element returns a Promise
          const playPromise = muxPlayer.play()
          if (playPromise !== undefined) {
            playPromise.then(() => {
              muxPlayer.pause()
              if (!isMuted) {
                muxPlayerRef.current.muted = false
              }
            })
          }
        }, { once: true })
      } else if (muxPlayer.paused || muxPlayer.ended) {
        muxPlayer.play()
      }
      muxPlayer.currentTime = ms / 1000
    }
  }, [muxPlayerRef])

  const jumpToFirstShot = useCallback((pause = true) => {
    // Find first shot that does not have isContext set to true
    const firstShot = rallies.find((shot) => !shot.isContext)
    jumpToRallyMS(rallies[0].mStart, pause)
    setRallyNumber(firstShot.rallyIdx)
    setSelected({ rallyIdx: firstShot.rallyIdx, shotIdx: firstShot.shotIdx, ms: firstShot.mStart })
  }, [jumpToRallyMS, rallies, setRallyNumber])

  const on3dShotViewRequested = useCallback(({ shot, timeInSeconds }) => {
    setSelected({ rallyIdx: shot.rallyIdx, shotIdx: shot.shotIdx, ms: shot.mStart })
    setRallyNumber(shot.rallyIdx)

    const muxPlayer = muxPlayerRef.current
    if (muxPlayer) {
      jumpToRallyMS(timeInSeconds * 1000)
      // Play the shot being requested
      muxPlayer.play()
    }
  }, [jumpToRallyMS, muxPlayerRef, setRallyNumber])

  const handleClearFilters = () => {
    dispatch(clearFilters())
  }

  const playerAvatars = useMemo(() =>
    players.map((p) => (
      // eslint-disable-next-line react/jsx-key
      <PlayerImage numberOfImages={1} imageWidth={100} className={`img player${p + 1}`} width={56} height={56} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={p} text={userData.players[p]?.name || `Player ${p + 1}`} hasPopup={false} />
    ))
  , [aiEngineVersion, userData.players, vid])

  const stats = useMemo(() => {
    const shotStats = Object.assign({}, shotTypeQuantity)
    for (let i = 0; i < rallies.length; i++) {
      let hasError = false
      const rally = rallies[i]
      // Skip isContext rallies - they are only for playback
      if (!rally.isContext) {
        if (rally.shot.shot_type) {
          shotStats[rally.shot.shot_type] += 1
        }
        if (!sequenceStatsCache.current && rally.shotSequence) {
          shotStats[rally.shotSequence] += 1
        }
        if (rally.shot.errors?.faults?.net === true) {
          shotStats.net += 1
          hasError = true
        }
        if (rally.shot.is_speedup === true) {
          shotStats.speedup += 1
        }
        if (rally.shot.is_final) {
          if (rally.shot.errors?.faults?.out) {
            hasError = true
            shotStats.out += 1
          }
          shotStats.final += 1
        }
        if (!hasError) {
          shotStats.none = shotStats.none + 1
        }
      }
    }
    if (sequenceStatsCache.current) {
      Object.assign(shotStats, sequenceStatsCache.current)
    } else if (rallies.length) {
      // cache only once rallies are initialized
      sequenceStatsCache.current = pick(shotStats, ['net', 'out', 'none', ...rallySequenceValues])
    }
    return shotStats
  }, [rallies])

  const winningStats = useMemo(() => {
    if (filterWinChanged.current) {
      filterWinChanged.current = false
      return winStatCache.current
    }
    const stats = [0, 0]
    const rallyIndices = []
    if (!dataLoaded) return stats
    for (let i = 0; i < rallies.length; i++) {
      const shot = rallies[i]
      // Collect rally indices to avoid counting the same rally multiple times
      if (rallyIndices.includes(shot.rallyIdx)) {
        continue
      }
      rallyIndices.push(shot.rallyIdx)
      if (shot.rally.winning_team === 0) {
        stats[0]++
      } else {
        stats[1]++
      }
    }
    // Cache the stats for the filter change (prevent recalculation when win filter is changed)
    winStatCache.current = stats
    return stats
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded, filters])

  // Calculate kitchen stats on initial rallies load (they will not change)
  const rallyKitchenStats = useMemo(() => {
    if (!dataLoaded) return {}
    const stats = {}

    // Track indices of rallies that are already processed for each player combination
    // this is to avoid counting stats when players are filtered out and the rally is already processed
    const rallyIndices = allPlayerCombinations.reduce((acc, c) => {
      acc[c.join('')] = []
      return acc
    }, {})

    for (let i = 0; i < rallies.length; i++) {
      const shot = rallies[i]
      // Who served the first shot
      const firstShotPlayerId = shot.rally.shots[0].player_id
      const rallyPlayers = shot.rally.players

      for (const selectedPlayers of allPlayerCombinations) {
        // Key for stats storage
        const key = selectedPlayers.join('')
        // Continue if the rally is already processed for this player combination
        if (rallyIndices[key].includes(shot.rallyIdx) || !selectedPlayers.includes(shot.shot.player_id)) {
          continue
        }
        rallyIndices[key].push(shot.rallyIdx)

        const isServing = (firstShotPlayerId > 1) === (selectedPlayers[0] > 1)
        if (!stats[key]) stats[key] = { servingYes: 0, servingNo: 0, receivingYes: 0, receivingNo: 0 }

        const yes = selectedPlayers.every((playerIdx) => rallyPlayers[playerIdx]?.ms_to_kitchen !== undefined)
        const no = selectedPlayers.every((playerIdx) => rallyPlayers[playerIdx]?.ms_to_kitchen === undefined)

        if (isServing) {
          stats[key].servingYes += yes
          stats[key].servingNo += no
        } else {
          stats[key].receivingYes += yes
          stats[key].receivingNo += no
        }
      }
    }
    return stats
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded])

  useEffect(() => {
    const muxPlayer = muxPlayerRef.current
    const extraMillisBeforeStart = 1000
    const extraMillisAfterEnd = 2000

    function stepTrough () {
      // Do not evaluate when player is paused (user clicks on a shot) or if we are in looping mode
      if (muxPlayer.paused || hasLooping) return
      const hasBeforeWindow = Boolean(filters.shotWindow.numBefore)

      const time = muxPlayer.currentTime * 1000
      let i = rallies.length
      while (i--) {
        const m = rallies[i]
        if ((m.rallyIdx === selected?.rallyIdx && m.shotIdx < selected.shotIdx) || (selected?.shotIdx === 0 && selected.rallyIdx > m.rallyIdx)) {
          // Do not go pass (backward) the selected shot, it should remain highlighted
          break
        }
        if (time > m.mStart) {
          if (time < m.mEnd) {
            if ((!selected || !(selected.shotIdx === m.shotIdx && selected.rallyIdx === m.rallyIdx))) {
              let shotToSelect = m
              // If shot has isContext - it's in the shot window but filtered out
              if (m.isContext) {
                if (hasBeforeWindow) {
                  // find the first previous shot without this flag that is in the same rally
                  const firstPrevShot = rallies.find((shot) => shot.shotIdx > m.shotIdx && shot.rallyIdx === m.rallyIdx && !shot.isContext)
                  if (firstPrevShot) {
                    shotToSelect = firstPrevShot
                  } else {
                    // In case we didn't find next shot in the current rally it might be numAfter context
                    break
                  }
                } else {
                  // Do not select shots with isContext set to true
                  break
                }
              }
              setSelected({ rallyIdx: shotToSelect.rallyIdx, shotIdx: shotToSelect.shotIdx, ms: shotToSelect.mStart - extraMillisBeforeStart, auto: true })
              setRallyNumber(shotToSelect.rallyIdx)
            }
            // No need to iterate any more - we are inside a displayed shot time range
            break
          } else {
            if (!noFilter) {
              // past this moment - jump to the next moment in filtered array if there is a filter applied
              // otherwise playback is controlled by the hook with interval
              const nextM = rallies[i + 1]
              if (nextM) {
                if (time < nextM.mStart - extraMillisAfterEnd) {
                  jumpToRallyMS(nextM.mStart - extraMillisBeforeStart)
                }
              } else {
                // Last rally / shot finished playing
                if (time > m.mEnd + extraMillisAfterEnd) {
                  // When a “playlist” is complete restart the playing of the clips from the top.
                  jumpToFirstShot(false)
                }
              }
            }
            // Found the next moment to show, break the loop even if the next shot doesn't exist (last one)
            break
          }
        } else if (!noFilter && i === 0 && time < m.rStart) {
          // fast forward beginning of the video to the first rally start
          jumpToRallyMS(m.rStart)
        }
      }
    }

    function rememberRate () {
      playbackRate.current = muxPlayer.playbackRate
    }

    if (muxPlayer) {
      muxPlayer.addEventListener('timeupdate', stepTrough)
      muxPlayer.addEventListener('ratechange', rememberRate)
    }
    return () => {
      muxPlayer?.removeEventListener('timeupdate', stepTrough)
      muxPlayer?.removeEventListener('ratechange', rememberRate)
    }
  }, [noFilter, selected, rallies, filters.shotWindow.numBefore, jumpToFirstShot, setRallyNumber, muxPlayerRef, jumpToRallyMS, hasLooping])

  useEffect(() => {
    // Track play/pause state
    const muxPlayer = muxPlayerRef.current
    const onPlay = () => setPlaying(true)
    const onPause = () => setPlaying(false)
    if (muxPlayer) {
      muxPlayer.addEventListener('play', onPlay)
      muxPlayer.addEventListener('pause', onPause)
      setPlaying(!muxPlayer.paused)
    }
    return () => {
      muxPlayer?.removeEventListener('play', onPlay)
      muxPlayer?.removeEventListener('pause', onPause)
    }
  }, [muxPlayerRef, vid])

  useEffect(() => {
    const s = document.querySelector('.shot.selected')
    if (selected && s) {
      // Replace condition with this to have the shot placement not react on user shot click
      // if (selected?.auto) {
      const s = document.querySelector('.shot.selected')
      // .shots container
      const scrollableContainer = s.parentElement.parentElement.parentElement.parentElement.parentElement
      // Just be sure element is found
      if (scrollableContainer) {
        const sHeight = s.clientHeight
        const cHeight = scrollableContainer.offsetHeight
        // Uncomment this to scroll only when the selected item is out of the view
        // if (s.offsetTop - sHeight < c.scrollTop || c.scrollTop + cHeight + sHeight < s.offsetTop) {
        scrollableContainer.scrollTop = s.offsetTop - scrollableContainer.offsetTop - cHeight / 2 + sHeight / 2
        // }
      }
    }
  }, [selected])

  useEffect(() => {
    if (selected) {
      const shot3d = shotsFor3DViewer.find((s) => s.originalShot.rallyIdx === selected.rallyIdx && s.originalShot.shotIdx === selected.shotIdx)
      if (shot3d) {
        const event = new CustomEvent('select3dShot', { detail: shot3d })
        document.dispatchEvent(event)
      }
    }
  }, [selected, shotsFor3DViewer])

  useEffect(() => {
    if (searchParams.size) {
      let queryFilters = searchParams.toString()
      queryFilters = Object.fromEntries(new URLSearchParams(queryFilters))
      if (queryFilters.filters) {
        queryFilters = JSON.parse(decodeURI(queryFilters.filters))
        dispatch(setAllFilters(normalizeFilters(queryFilters)))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Check if there are only query filters and no shot/loop params
    // In case of shot/loop params, they are handled by use-video-params hook and should not trigger auto select
    const hasOnlyQueryFilters = Boolean(searchParams.size ? searchParams.get('filters') && !hasLooping : false)
    // WA-495 SE - play from filtered selection (select first filtered shot when filters are passed trough query string)
    // If there are initialized filters and query string is not empty - select first available shot
    if (rallies.length && !noFilter && hasOnlyQueryFilters && !autoSelectOnQuery.current) {
      // Set the flag to true to trigger only once during the initial rendering
      autoSelectOnQuery.current = true
      jumpToFirstShot()
    }
  }, [hasLooping, jumpToFirstShot, noFilter, rallies, searchParams])

  // WA-867 clear t/l params when reloading the page
  useEffect(() => {
    const clearSearchParams = (event) => {
      const search = new URLSearchParams(window.location.search)
      if (search.get('t') && search.get('l')) {
        window.localStorage.setItem('clearSearchParams', 'true')
      }
    }

    window.addEventListener('beforeunload', clearSearchParams)

    return () => {
      window.removeEventListener('beforeunload', clearSearchParams)
    }
  }, [])

  useEffect(() => {
    if (window.localStorage.getItem('clearSearchParams') === 'true') {
      const queryFilters = new URLSearchParams(searchParams.toString())
      queryFilters.delete('t')
      queryFilters.delete('l')
      const url = new URL(window.location)
      url.search = queryFilters.toString()
      window.history.replaceState({}, document.title, url)
      window.localStorage.removeItem('clearSearchParams')
      setSelected()
    }
  }, [jumpToFirstShot, searchParams])

  useEffect(() => {
    // Do this only once when data is loaded
    if (rallies.length && !selectFromQuery.current) {
      // Find currently selected shot based on the query string
      const t = searchParams.get('t')
      const l = searchParams.get('l')
      if (t && l) {
        const shotStart = rallies.find((r) => r.mStart === Number(t) * 1000)
        if (shotStart) {
        // Find the shot + contextAfter the shot in the same rally
          const selectedShot = rallies.find((r) => r.rallyIdx === shotStart.rallyIdx && r.shotIdx === Math.min(rallies.filter((r) => r.rallyIdx === shotStart.rallyIdx).length - 1, shotStart.shotIdx + filters.shotWindow.numAfter))

          setSelected({ rallyIdx: selectedShot.rallyIdx, shotIdx: selectedShot.shotIdx, ms: selectedShot.mStart })
          setRallyNumber(selectedShot.rallyIdx)
        }
      }
      selectFromQuery.current = true
    }
  }, [rallies, filters.shotWindow.numAfter, searchParams, setRallyNumber])

  useEffect(() => {
    // Detect when filters are cleared from the header and update the query parameters (only when drawer is closed)
    if (!showDrawer) {
      const diff = diffFilters(filters)
      const hasFilters = Object.keys(diff).length
      // Use location.search instead of searchParams as searchParams can be stale
      const search = new URLSearchParams(window.location.search)
      const t = search.get('t')
      const l = search.get('l')
      const additionalParams = t || l ? { ...(t ? { t } : {}), ...(l ? { l } : {}) } : false

      if (!hasFilters) {
        if (additionalParams) {
          setSearchParams(additionalParams)
        } else {
          setSearchParams()
        }
      } else {
        const filtersQuery = JSON.stringify(diff)
        setSearchParams({ filters: filtersQuery, ...(additionalParams || {}) })
      }
    }
  }, [filters, searchParams, setSearchParams, showDrawer])

  if (workflow && !isWorkflowDone) {
    return <SkeletonComponent rootClassName='video-sidebar-column' />
  }

  return (
    <Container className='shot-explorer-aside video-sidebar-column'>
      <aside>
        <div>
          <header>
            <div className='row'>
              <h3>Shots</h3>
              <Button variant='outlined' color='midnight' className='neutral-outline' onClick={() => setShowDrawer(true)}>
                <AddIcon />
                <em>Filter</em>
              </Button>
            </div>
            <CurrentFilters userData={userData} filters={filters} stats={stats} updateFilter={updateFilter} />
          </header>
          {rallies?.length
            ? (
              <div className='shots'>
                <Rallies playerAvatars={playerAvatars} list={rallies} selected={selected} setSelected={updateSelected} muxPlayerRef={muxPlayerRef} isPlaying={isPlaying} contextBefore={filters.shotWindow.numBefore} contextAfter={filters.shotWindow.numAfter} />
              </div>
              )
            : (
              <NoShotsFound clear={handleClearFilters} />
              )}
        </div>
      </aside>
      <FilterSideBar video={video} vid={vid} aiEngineVersion={aiEngineVersion} userData={userData} filters={filters} stats={stats} handleClearFilters={handleClearFilters} updateFilter={updateFilter} showDrawer={showDrawer} setShowDrawer={setShowDrawer} rallyKitchenStats={rallyKitchenStats} winningStats={winningStats} />
      {portalRef.current && createPortal((
        <ShotsViewer
          shots={shotsFor3DViewer}
          onShotViewRequested={on3dShotViewRequested}
          playbackId={video?.mux?.playbackId}
        />), portalRef.current)}
    </Container>
  )
}
