import { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { Players } from './Players'
import { Shortcuts } from './Shortcut'

import useMobileDetect from '@/hooks/use-mobile-detect'
import { TAB } from '@/pages/home/sidebar'
import { APIContext } from '@/utils/api'

export function MobileHomeScreenShortcut () {
  const isMobile = useMobileDetect()
  const { tab: activeTab } = useParams()

  const { workflow, getPlayerName } = useContext(APIContext)
  const aiEngineVersion = workflow?.aiEngineVersion

  // if workflow has error set or if workflow is done but insights isn't ready, then processing failed
  const hasError = workflow.error || (workflow?.noInsights && Boolean(workflow?.epochFinished))

  if (!(isMobile && activeTab === TAB.OVERVIEW)) {
    return null
  }

  return (
    <div className='full'>
      <Shortcuts hasError={hasError} />
      <Players getPlayerName={getPlayerName} aiEngineVersion={aiEngineVersion} hasError={hasError} />
    </div>
  )
}
