function createExploreUrl (typeId, playerId, paramList) {
  // When paramList is provided, types includes 'in' as well
  const types = [...(paramList ? ['in'] : []), 'net', 'out']
  const params = new URLSearchParams()
  const type = types[typeId]

  const filters = {
    ...(paramList?.sequences ? { sequences: paramList.sequences } : {}),
    players: [playerId],
    errors:
      [type === 'in' ? 'none' : type]
  }
  params.append('filters', JSON.stringify(filters))

  // Construct the full URL
  const url = `explore?${params.toString()}`
  return url
}

export default createExploreUrl
