import { Slide } from '@mui/material'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AlertBanner } from './banner'
import { BuyMoreCreditsBannerActions, BuyMoreCreditsMessage, CreateAccountActions, ExploreSubscriptionPlansActions, TrialHaventStart, TrialModeExpired, UpgradeBeforeFreeTrialEndsActions, UpgradeBeforeFreeTrialEndsMessage } from './variations/monetization-actions'
import { VideoEditingUnavailableMessage } from './variations/video-alerts'

import useMobileDetect from '@/hooks/use-mobile-detect'
import { hideBanner, removeBanner } from '@/store/controls'
import { BANNER_KEYS } from '@/utils/banners'
import { scrollToTopOfMain } from '@/utils/helpers/scroll-to-top'

/**
 *  This component is designed to listen for Redux changes
 *  related to banners and display them
 */

/**
 * We have a few variations of the banner, and there will be more.
 * The idea behind the actions/message variations is to allow as many
 * variations as we need without having to modify the AlertBanner component
 * and to avoid overcomplicating it.
 */
const actionsVariations = {
  [BANNER_KEYS.UNSUBSCRIBED_OUT_OF_CREDIT]: <ExploreSubscriptionPlansActions />,
  [BANNER_KEYS.SUBSCRIBED_OUT_OF_CREDIT]: <BuyMoreCreditsBannerActions />,
  [BANNER_KEYS.ANONYMOUS_PROMPT_TO_CREATE_ACCOUNT]: <CreateAccountActions />,
  [BANNER_KEYS.UPGRADE_BEFORE_FREE_TRIAL]: <UpgradeBeforeFreeTrialEndsActions />,
  [BANNER_KEYS.FREE_TRIAL_EXPIRED]: <TrialModeExpired />,
  [BANNER_KEYS.TRIAL_HAVENT_START]: <TrialHaventStart />
}

const messageVariations = {
  [BANNER_KEYS.SUBSCRIBED_OUT_OF_CREDIT]: <BuyMoreCreditsMessage />,
  [BANNER_KEYS.UPGRADE_BEFORE_FREE_TRIAL]: <UpgradeBeforeFreeTrialEndsMessage />,
  [BANNER_KEYS.NOT_YOUR_VIDEO]: <VideoEditingUnavailableMessage />
}

export const BannerDispatcher = () => {
  const { alerts } = useSelector(state => state.controls.banners)
  const dispatch = useDispatch()
  const nodeRef = useRef(null)

  const isMobile = useMobileDetect()

  const intoView = () => {
    scrollToTopOfMain()
    if (nodeRef?.current) {
      // Be sure banner has done with animation before showing it
      setTimeout(() => {
        if (nodeRef.current) { // Check again here to ensure it exists after timeout
          const rect = nodeRef.current.getBoundingClientRect()
          const doc = document.documentElement

          // if element in viewport don't scroll into view
          if (
            !(rect.top >= 0 &&
              rect.left >= 0 &&
              rect.bottom <= (window.innerHeight || doc.clientHeight) &&
              rect.right <= (window.innerWidth || doc.clientWidth))
          ) {
            nodeRef.current.scrollIntoView({ behavior: 'smooth' })
          }
        }
      }, 500)
    }
  }

  /**
   * Annoying banners will continue to appear as many times as necessary,
   * regardless of whether the user closes them.
   */
  function onRemove (isAnnoying, id) {
    if (isAnnoying) {
      dispatch(removeBanner(id))
    } else {
      dispatch(hideBanner(id))
    }
  }

  return (
    <>
      {alerts.map(alert => (
        <Slide key={alert.id} nodeRef={nodeRef} onExited={() => onRemove(alert.isAnnoying, alert.id)} direction='down' in={!alert.isDismissed} onEntered={intoView} mountOnEnter unmountOnExit>
          <AlertBanner
            id={alert.id}
            ref={nodeRef}
            onRemove={() => onRemove(alert.isAnnoying, alert.id)}
            title={alert.title}
            message={messageVariations[alert.id] || alert.message}
            actions={actionsVariations[alert.id]}
            autoDismiss={alert.autoDismiss}
            dismissibleOnMobile={alert.dismissibleOnMobile}
            permanent={alert.permanent}
            type={alert.type}
            oneLine={!isMobile && alert.oneLine}
          />
        </Slide>
      ))}
    </>
  )
}
