import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import { useSelector } from '@xstate/store/react'
import { useCallback, useState } from 'react'

import './Inspector.css'

import CloseIcon from '@/assets/shots-viewer/close.svg?react'
import PlayIcon from '@/assets/shots-viewer/play.svg?react'
import { SHOTS_ARRAY_SINGULAR } from '@/components/player-stats/stats-sections/selected-shots-viewer'
import { getColorForShot } from '@/utils/stats'
import { capitalize } from '@/utils/string'
import transparentPixel from '@/utils/transparent-pixel'

function urlForShot (shot) {
  const url = new URL(window.location)
  const params = new URLSearchParams(url.search)
  params.set('t', shot.start.ms / 1000)
  url.search = params.toString()
  return url
}

const qualities = ['Poor', 'Fair', 'Average', 'Good', 'Excellent']

// via getShotTrajectoryColorByValue
function interpolate (array, value) {
  return array[Math.min(Math.floor(value * array.length), array.length - 1)]
}

function Image ({ src }) {
  const [hasLoaded, setHasLoaded] = useState(false)

  const style = {
    transition: 'opacity 150ms ease-in 0ms',
    opacity: hasLoaded ? 1 : 0
  }

  return (
    <>
      <div style={{
        position: 'absolute',
        inset: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        color: 'rgb(0, 0, 0, 0.4)',
        outline: 'rgb(0, 0, 0, 0.125) solid 1px',
        borderRadius: 3,
        transition: 'opacity 150ms ease-in 0ms',
        opacity: !hasLoaded ? 1 : 0
      }}
      >
        Loading …
      </div>
      <img
        src={src}
        onLoad={() => setHasLoaded(true)}
        style={style}
      />
    </>
  )
}

export function Inspector ({ store, playbackId, onShotViewRequested }) {
  const selection = useSelector(store, state => state.context.selection)

  const onCloseClick = useCallback(function (event) {
    event.preventDefault()
    store.send({ type: 'selected', selection: null })
  }, [store])

  const onViewClick = useCallback(function (event) {
    event.preventDefault()
    onShotViewRequested({
      timeInSeconds: (selection.start.ms / 1000) - 3,
      shot: selection.originalShot
    })
  }, [onShotViewRequested, selection])

  if (selection == null) return null

  const startTimeInSeconds = selection.start.ms / 1000
  const minutes = Math.floor(startTimeInSeconds / 60)
  const seconds = Math.round(startTimeInSeconds % 60)
  const mm = minutes.toString().padStart(2, '0')
  const ss = seconds.toString().padStart(2, '0')

  const {
    shotIdx,
    player_id: playerId,
    shot_type: shotType,
    quality,
    resulting_ball_movement: { speed }
  } = selection.originalShot

  const label = selection.originalShot.is_final && selection.originalShot.errors?.faults ? 'Fault' : interpolate(qualities, quality.overall)
  const title = selection.originalShot.is_final && selection.originalShot.errors?.faults ? 'Fault' : `${Math.round(quality.overall * 100)}%`

  const shotName = SHOTS_ARRAY_SINGULAR[shotIdx] || `${shotIdx}th`

  return (
    <dialog className='Inspector' open>
      <div className='row row_wider'>
        <div>
          <a
            className='thumbnail-container'
            onClick={onViewClick}
            href={urlForShot(selection).toString()}
          >
            <Image
              key={`img-${playbackId}-${startTimeInSeconds}`}
              src={
               playbackId ? `https://image.mux.com/${playbackId}/thumbnail.png?time=${startTimeInSeconds}&width=512` : transparentPixel
              }
            />
          </a>
        </div>
        <div>
          <div className='player'>
            Player {playerId + 1}
          </div>
        </div>
      </div>
      <div className='row'>
        <div>
          <span className='shotType'>
            {/* If shotIdx === 0 (serve) OR shotIdx === 1 (return), we will just display the shotIdx. Else, we will show the shotIdx plus the shot type. */}
            {`${shotName}${shotIdx > 1 ? ` ${capitalize(shotType)}` : ''}`}
          </span>
        </div>
        <div>
          <Chip
            variant='filled'
            title={title}
            label={label}
            className='quality'
            sx={{
              color: 'white',
              fontWeight: 'bold',
              backgroundColor: getColorForShot(selection.originalShot)
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div>
          <span className='time'>
            {mm}:{ss}
          </span>
        </div>
        <div>
          <span className='speed'>
            {Math.round(speed)}mph
          </span>
        </div>
      </div>

      <div className='column' style={{ justifyContent: 'flex-end', flex: 1 }}>
        <Button
          variant='outlined'
          className='view'
          onClick={onViewClick}
          href={urlForShot(selection).toString()}
        >
          Watch in Video
          <PlayIcon />
        </Button>
      </div>

      <form method='dialog'>
        <button className='close' autoFocus onClick={onCloseClick}>
          <CloseIcon />
        </button>
      </form>
    </dialog>
  )
}
