import { useCallback, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

export const useVideoParams = () => {
  const location = useLocation()
  const intervalIdRef = useRef(null)
  const localMuxPlayerRef = useRef(null)
  const startTimeRef = useRef(null)
  const endTimeRef = useRef(null)

  const handleClearInterval = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
      intervalIdRef.current = null
    }
  }

  const handleWithVideoParams = useCallback((muxPlayer, ignoreTParamRef) => {
    localMuxPlayerRef.current = muxPlayer
    // Use window.location.search instead of location.search as location.search is not updated immediately
    const searchParams = new URLSearchParams(window.location.search)
    const startTime = Number(searchParams.get('t'))
    const loopLength = Number(searchParams.get('l'))
    const playbackRate = Number(searchParams.get('speed'))

    startTimeRef.current = startTime
    endTimeRef.current = startTime + loopLength

    if (muxPlayer) {
      const mediaTheme = muxPlayer.shadowRoot?.querySelector('media-theme')
      const mediaTimeRange = mediaTheme?.shadowRoot?.querySelector('media-time-range')
      mediaTimeRange?.removeEventListener('click', handleSeek)
      mediaTimeRange?.addEventListener('click', handleSeek)
    }

    if (startTime) {
      if (ignoreTParamRef.current) {
        ignoreTParamRef.current = false

        handleClearInterval()
      } else {
        muxPlayer.currentTime = startTime
      }
    }

    if (playbackRate) {
      // Set the playback rate if it is specified in the URL, with a maximum of 5
      muxPlayer.playbackRate = Math.min(5, playbackRate)
    }

    if (loopLength) {
      const handleProgress = () => {
        const startT = startTimeRef.current
        const endT = endTimeRef.current

        // loop back to the start time
        if (endT && muxPlayer.currentTime > endT) {
          muxPlayer.currentTime = startT
        }
      }

      muxPlayer.addEventListener('pause', handlePause)

      intervalIdRef.current = setInterval(handleProgress, 500)
    }

    if (startTime || loopLength) {
      // Mute the video to increase the chance of autoplay working and prevent the console error
      // https://docs.mux.com/guides/web-autoplay-your-videos#increase-your-chance-of-autoplay-working
      // muxPlayer.muted = true

      if (muxPlayer.paused || muxPlayer.ended) {
        // Play the video but only after metadata is loaded to prevent
        // `AbortError: The play() request was interrupted by a call to pause()`
        muxPlayer.addEventListener('loadeddata', () => {
          muxPlayer.play()
        }, { once: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handlePause = () => {
    handleClearInterval()
    localMuxPlayerRef.current.removeEventListener('pause', handlePause)
  }

  const handleSeek = () => {
    handleClearInterval()
  }

  useEffect(() => {
    if (localMuxPlayerRef.current) {
      const mediaTheme = localMuxPlayerRef.current?.shadowRoot?.querySelector('media-theme')
      const mediaTimeRange = mediaTheme?.shadowRoot?.querySelector('media-time-range')
      mediaTimeRange?.addEventListener('click', handleSeek)
    }
    return () => {
      if (localMuxPlayerRef.current) {
        const mediaTheme = localMuxPlayerRef.current?.shadowRoot?.querySelector('media-theme')
        const mediaTimeRange = mediaTheme?.shadowRoot?.querySelector('media-time-range')
        mediaTimeRange?.removeEventListener('click', handleSeek)
      }
      handleClearInterval()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Clear the interval when searchParams change and startTime is emptied
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    if (!searchParams.get('t') && !searchParams.get('l')) {
      handleClearInterval()
    }
  }, [location])

  return { handleWithVideoParams }
}
