import { styled } from '@mui/material'

import COLORS from '@/utils/colors'

export const VideoEditingUnavailableMessage = () => {
  return (
    <Container>
      You don’t have permission to edit this video because you’re not the owner. If this is incorrect, please make sure you’re logged in and reference this <a target='_blank' rel='noopener noreferrer' href='https://help.pb.vision/en/help/articles/1717241-editing-a-video-ownership-warning'>help article</a>.
    </Container>
  )
}

const Container = styled('div')({
  '> a': {
    color: COLORS['danger-500'],
    textDecoration: 'underline'
  }
})
