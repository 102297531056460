import { styled } from '@mui/material'
import clsx from 'clsx'
import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { ShotViewer } from '../../explorer'
import { useIsFilterEmpty } from '../../explorer/hooks'
import { useVideoParams } from '../../hooks/use-video-params'

import { Highlights } from '@/components/highlights'
import { MobileHomeScreenShortcut } from '@/components/shortcut/MobileHomeScreenShortcut'
import VideoPage from '@/components/video-player'
import { useURLParams } from '@/hooks/use-url-params'
import { useVideoExcerpt } from '@/store/library'
import { useShotFilters } from '@/store/shot-filter'
import { APIContext } from '@/utils/api'
import { column, row } from '@/utils/flexGrid'
import theme from '@/utils/theme'

const Content = () => {
  // This is a fake component that is used to trigger the useEffect in the player page
  // in order to update the video time based on the URL params / convert the state to
  // explore if the URL has the state parameter etc.
  const { muxPlayerRef } = useContext(APIContext)
  const { handleWithVideoParams } = useVideoParams()
  const params = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const vid = params.vid
  const ignoreTParamRef = useRef(false)
  const [isSettingFlag, setIsSettingFlag] = useState(false)
  const { removeSearchParams } = useURLParams()

  useEffect(() => {
    const handleRemoveParams = () => {
      setIsSettingFlag(true)
    }

    const handleSeek = () => {
      setIsSettingFlag(true)
    }

    const attachListeners = () => {
      const muxPlayer = document.querySelector('mux-player')
      if (muxPlayer?.shadowRoot) {
        const mediaTheme = muxPlayer.shadowRoot.querySelector('media-theme')
        if (mediaTheme?.shadowRoot) {
          const mediaController = mediaTheme.shadowRoot.querySelector('media-controller')
          if (mediaController) {
            const mediaTimeRange = mediaController.querySelector('media-time-range')
            if (mediaTimeRange) {
              mediaTimeRange.addEventListener('click', handleRemoveParams)
            }

            const bottomControlBar = mediaController.querySelector('media-control-bar[part="control-bar bottom"]')
            if (bottomControlBar) {
              const seekBackwardButton = bottomControlBar.querySelector('media-seek-backward-button')
              const seekForwardButton = bottomControlBar.querySelector('media-seek-forward-button')

              if (seekBackwardButton) {
                seekBackwardButton.addEventListener('click', handleSeek)
              }
              if (seekForwardButton) {
                seekForwardButton.addEventListener('click', handleSeek)
              }
            }

            // Stop observing once listeners are attached
            if (observer) observer.disconnect()
          }
        }
      }
    }

    // Observe for changes in the DOM
    // eslint-disable-next-line no-undef
    const observer = new MutationObserver(attachListeners)
    observer.observe(document.body, { childList: true, subtree: true })

    // Cleanup
    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    if (isSettingFlag) {
      ignoreTParamRef.current = true
      removeSearchParams('l')
      setIsSettingFlag(false)
    }
  }, [removeSearchParams, isSettingFlag])

  useEffect(() => {
    if (isSettingFlag) {
      return
    }

    const muxPlayer = muxPlayerRef.current

    if (muxPlayer) {
      handleWithVideoParams(muxPlayer, ignoreTParamRef)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [muxPlayerRef.current, isSettingFlag, handleWithVideoParams])

  useEffect(() => {
    // When url is shared with state = explore, redirect the user to /video/[vid]/explore page
    const queryState = searchParams.get('state')
    try {
      if (queryState) {
        const query = JSON.parse(decodeURI(queryState))
        if (query.path === 'explore') {
          const { path, ...filters } = query
          navigate({ pathname: `/video/${vid}/explore`, search: `filters=${encodeURI(JSON.stringify(filters))}` }, { replace: true })
        }
      }
    } catch (err) {
      console.error(`Query string ${queryState} not recognized.`)
    }
  }, [navigate, searchParams, vid])

  return null
}

export default function HomeTab ({ PAGE_SETTINGS, isShotExplorer }) {
  const { muxPlayerRef, video, insights, isTheaterModeEnabled, workflow } = useContext(APIContext)
  const { updateSearchParams, removeSearchParams } = useURLParams()
  const params = useParams()
  const vid = params.vid
  const filters = useShotFilters()
  const noFilter = useIsFilterEmpty(filters)
  const { videoExcerpt } = useVideoExcerpt(vid)
  const portalRef = useRef(null)

  function jumpToVideoTime (secsInOriginalVideo) {
    const muxPlayer = muxPlayerRef.current
    if (muxPlayer) {
      if (muxPlayer.paused || muxPlayer.ended) {
        muxPlayer.play()
      }
      updateSearchParams('t', secsInOriginalVideo)
      removeSearchParams('l')
      muxPlayer.currentTime = secsInOriginalVideo
    }
  }

  function listenForRateChangeAndUpdateParams () {
    const currentRate = muxPlayerRef.current.playbackRate

    updateSearchParams('speed', currentRate)
  }

  return (
    <div className='insights-group'>
      {isShotExplorer && <h1>Shot Explorer</h1>}
      <Container className={clsx('insights-row-grid', { explorer: isShotExplorer }, { 'theater-mode': isTheaterModeEnabled })}>
        <VideoPage
          jumpToVideoTime={jumpToVideoTime}
          onRateChange={listenForRateChangeAndUpdateParams}
          muxPlayerRef={muxPlayerRef}
          playerContent={<Content />}
          settings={PAGE_SETTINGS}
          portalRef={portalRef}
        >
          {isShotExplorer &&
            <ShotViewer filters={filters} noFilter={noFilter} portalRef={portalRef} />}
        </VideoPage>
        {PAGE_SETTINGS.showHighlights && (
          <Highlights
            className='video-sidebar-column without-padding'
            videoExcerpt={videoExcerpt}
            userData={video.userData}
            insights={insights}
            onHighlightClicked={highlight => jumpToVideoTime(highlight.s / 1000)}
            workflow={workflow}
          />
        )}
      </Container>
      <MobileHomeScreenShortcut />
    </div>
  )
}

const Container = styled('div')({
  ...row,
  gap: theme.spacing(2),
  '& .highlights': {
    borderRadius: '0px !important'
  },
  '&.explorer': {
    '& .highlights': {
      width: '100%'
    }
  },
  '&.theater-mode': {
    ...column,
    '& .video-player': {
      maxWidth: '100% !important'
    },
    '& .highlights': {
      width: '100%',
      '& .highlight-buttons': {
        ...row,
        flexWrap: 'wrap',
        gap: theme.spacing(1)
      },
      '& .combined-button': {
        display: 'flex',
        width: 'fit-content'
      }
    },
    '& .shot-explorer-aside aside': {
      height: 400,
      width: '100%'
    }
  }
})
