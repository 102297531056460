import PersonIcon from '@mui/icons-material/Person'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined'
import TabletIcon from '@mui/icons-material/Tablet'
import VideocamIcon from '@mui/icons-material/Videocam'
import Divider from '@mui/material/Divider'
import clsx from 'clsx'

import { Container, YouTubeEmbedContainer } from './container'

import instantReplayImageUrl from '@/assets/images/partner/instant-replay.png'
import oneTapRecordingImageUrl from '@/assets/images/partner/one-tap-recording.png'
import PBVisionLogo from '@/assets/images/partner/pb-vision-logo.svg?react'
import premiumCameraImageUrl from '@/assets/images/partner/premium-camera.png'
import Replay from '@/assets/images/partner/replay.svg?react'
import tabletImageUrl from '@/assets/images/partner/tablet-qr-code.png'
import youtubeLiveImageUrl from '@/assets/images/partner/youtube-live.png'
import pbVisHorizLogoUrl from '@/assets/pb-vis-horiz-logo.svg'
import PBVisHorizLogo from '@/assets/pb-vis-horiz-logo.svg?react'
import { Button } from '@/components/button'
import useMobileDetect from '@/hooks/use-mobile-detect'
import useZendesk from '@/hooks/use-zendesk'
import { ExploreLandingSection } from '@/pages/landing/sections/explore-section'

const ZendeskButton = ({ text = 'Get Started with Court Insights' }) => {
  const { toggleZendeskWidget } = useZendesk()

  return (
    <Button className='green' onClick={toggleZendeskWidget}>
      {text}
    </Button>
  )
}

const YouTubeEmbed = ({ videoId }) => (
  <YouTubeEmbedContainer>
    <iframe
      src={`https://www.youtube.com/embed/${videoId}`}
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
      allowFullScreen
    />
  </YouTubeEmbedContainer>
)

const EquipmentPricing = () => (
  <div className='pricing-section equipment-pricing'>
    <div className='header'>Equipment Pricing</div>
    <div className='items'>
      <div className='item'>
        <div className='item-header'>
          <PhotoCameraIcon />
          <div className='title'>Camera equipment</div>
        </div>
        <div className='item-description'>
          A one-time setup fee of $185 plus $49 per month, per court. Volume discounts are available on both hardware and software for clubs equipping multiple courts.
        </div>
      </div>
      <div className='item'>
        <div className='item-header'>
          <TabletIcon />
          <div className='title'>Camera control tablet (recommended)</div>
        </div>
        <div className='item-description'>
          For only $99 per court-side tablet, players can easily control recordings and access instant replays. This convenient setup significantly boosts engagement compared to QR codes.
        </div>
      </div>
    </div>
  </div>
)

const CustomerPricing = () => (
  <div className='pricing-section customer-pricing'>
    <div className='header'>Player/Customer Pricing</div>
    <div className='header-description'>
      For the PB Vision integration, we have two options that are configurable via the Save My Play camera portal at any time.
    </div>
    <div className='items'>
      <div className='item'>
        <div className='item-header'>
          <PersonIcon />
          <div className='title'>Player Managed Option</div>
        </div>
        <div className='item-description'>
          In this option, the club does not pay any fees nor manage any access to PB Vision. Players can choose to use PB Vision through their subscription. If it&apos;s their first time trying the system, we&apos;ll initiate a free trial and analyze the game.
        </div>
        <ZendeskButton text='Get Started' />
      </div>
      <div className='item'>
        <div className='item-header'>
          <PointOfSaleIcon />
          <div className='title'>Club Managed Option</div>
        </div>
        <div className='item-description'>
          The club sets the price for analytics game recordings through the Save My Play portal. Prices start at $5 per game (at cost), but the club can bill a higher cost to make a margin on the sale.
        </div>
        <ZendeskButton text='Get Started' />
      </div>
    </div>
  </div>
)

const CourtInsightsPackage = () => (
  <div className='package-contents'>
    <div className='header'>Our Court Insight package includes:</div>
    <div className='items'>
      <div className='package-item premium-camera'>
        <div className='image-pane'>
          <div className='image-container'>
            <img src={premiumCameraImageUrl} alt='Premium Camera Image' />
          </div>
        </div>
        <div className='description'>
          <div className='title'>Premium camera</div>
          <div className='subtitle'>
            Capture every detail of the game with our high-end, weatherproof camera, featuring an ultra-wide field of view.
          </div>
        </div>
      </div>
      <div className='package-item tablet'>
        <div className='image-pane'>
          <div className='image-container'>
            <img src={tabletImageUrl} alt='Tablet and QR Code Image' />
          </div>
        </div>
        <div className='description'>
          <div className='title'>Tablet or QR code</div>
          <div className='subtitle'>
            Record and share games effortlessly from court-side using an affordable tablet or a simple QR code to start recording.
          </div>
        </div>
      </div>
      <div className='package-item automatic-insights'>
        <div className='image-pane'>
          <div className='image-container'>
            <img src={pbVisHorizLogoUrl} alt='Automatic AI Insights Image' />
          </div>
        </div>
        <div className='description'>
          <div className='title'>Automatic AI insights</div>
          <div className='subtitle'>
            PB Vision converts each video into personalized insights, empowering players to learn from their performance seamlessly.
          </div>
        </div>
      </div>
    </div>
    <ZendeskButton />
  </div>
)

const SeeItInAction = () => (
  <div className='see-it-in-action'>
    <div className='header'>See it in action 👇</div>
    <YouTubeEmbed videoId='-LF73xvg8mk' />
  </div>
)

const ClubNearYou = () => (
  <div className='find-a-club-near-you'>
    <div className='title'>Find a Club Near You</div>
    <div className='iframe-container'>
      <iframe
        allowfullscreen
        className='iframe'
        src='https://www.google.com/maps/d/embed?mid=11IxLBJjqdZ5icN0D3BhrbWoeqa6af1A&ehbc=2E312F'
        title='Club Locations'
        referrerPolicy='no-referrer-when-downgrade'
        frameBorder='0'
        style={{ border: 0 }}
      />
    </div>
  </div>
)

const ReplaySection = () => (
  <div className='replay-section'>
    <div className='pane'>
      <div className='description'>
        <div className='title'>
          Instant Replay
        </div>
        <div className='subtitle'>
          Relive key moments immediately after they happen. Facilities can up-charge for highlights or offer a
          value-add to customer
        </div>
      </div>
      <div className='image-container'>
        <img src={instantReplayImageUrl} alt='Instant Replay Image' />
        <div className='image-overlay'>
          <Replay className='replay' />
        </div>
      </div>
    </div>
    <div className='pane'>
      <div className='description'>
        <div className='title'>
          Live Stream!
        </div>
        <div className='subtitle'>
          Live-stream directly from the court on YouTube or Facebook. Set-up is a breeze.
        </div>
      </div>
      <div className='image-container'>
        <img src={youtubeLiveImageUrl} alt='YouTube Live Image' />
      </div>
    </div>
  </div>
)

const ExploreSection = () => (
  <div className='explore-section'>
    <ExploreLandingSection hideUploadButton />
  </div>
)

const GameAnalytics = () => (
  <div className='game-analytics'>
    <div className='title'>Game Analytics powered by</div>
    <div className='logo-container'>
      <PBVisHorizLogo />
    </div>
  </div>
)

const SimpleOneTapRecording = () => (
  <div className='simple-one-tap-recording'>
    <div className='title'>Simple One-Tap Video Recording</div>
    <div className='image-container'>
      <img src={oneTapRecordingImageUrl} alt='One-Tap Recording Image' />
    </div>
  </div>
)

const CourtInsightsBanner = () => (
  <div className='court-insights'>
    <span className='header'>Court Insights</span>
    <div className='body'>
      <span className='from'>FROM</span>
      <PBVisionLogo />
    </div>
  </div>
)

const HeaderText = () => (
  <div className='header-text'>
    <div className='title'>
      Make your courts the talk of the pickleball community
    </div>
    <div className='subtitle'>
      Delight players and boost your revenue by upgrading your facility with AI-powered video stats, replays of
      great rallies, live streaming, and simple social sharing.
    </div>
    <div className='pricing'>
      $185 per camera + $49 per month per court
    </div>
  </div>
)

const HeaderButtons = () => (
  <div className='header-buttons'>
    <Button
      endIcon={<VideocamIcon />} className='gray' onClick={() => {
        window.open('https://www.youtube.com/watch?v=-LF73xvg8mk', '_blank')
      }}
    >See it in action
    </Button>
    <Button
      endIcon={<QuestionAnswerOutlinedIcon />} className='gray' onClick={() => {
        window.open('https://help.pb.vision/articles/9341690-court-insight-by-pb-vision#6f2q1wl88sq', '_blank')
      }}
    >Frequently Asked Questions
    </Button>
    <ZendeskButton />
  </div>
)

/**
 * @returns {object} JSX
 */
function PartnerPage () {
  const isMobile = useMobileDetect()

  return (
    <Container className={clsx([{ mobile: isMobile }])}>
      <CourtInsightsBanner />
      <HeaderText />
      <HeaderButtons />
      <SimpleOneTapRecording />
      <GameAnalytics />
      <ExploreSection />
      <ClubNearYou />
      <ReplaySection />
      <ZendeskButton />
      <SeeItInAction />
      <CourtInsightsPackage />
      <EquipmentPricing />
      <Divider />
      <CustomerPricing />
    </Container>
  )
}

export default PartnerPage
