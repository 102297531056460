import styled from '@emotion/styled'
import AnaliticsIcon from '@mui/icons-material/AnalyticsOutlined'
import { IconButton } from '@mui/material'
import clsx from 'clsx'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import HomeAPIWrapper from './context'
import { useIsFilterEmpty } from './explorer/hooks'
import { useClearLoop } from './hooks/use-clear-loop'
import HomePageLayout from './layout'
import HomeSidebar from './sidebar'
import GameStatsTab from './tabs/game-stats'
import HomeTab from './tabs/home'
import SinglePlayerInsightsTab from './tabs/player'
import TeamStatsTab from './tabs/team-stats'

import TaggingDialog from '@/components/tag-your-buds'
import { TitleBar } from '@/components/titlebar'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { resetSidebar, useSidebarVisible } from '@/store/controls'
import { useShotFilters } from '@/store/shot-filter'
import COLORS from '@/utils/colors'
import { isInMobileAppWebview, isVersionedFeatureAllowed } from '@/utils/mobile-app-communication'
import theme from '@/utils/theme'

function InsightsPage () {
  const isMobile = useMobileDetect()
  const { tab: activeTab } = useParams()
  const muxPlayerRef = useRef()
  const filters = useShotFilters()
  const noFilter = useIsFilterEmpty(filters)
  const pbvisionDiv = window.document.getElementById('pbvision')
  const [isExpanded, setIsExpanded] = useState(false)
  const isNativeFeatureEnabled = isVersionedFeatureAllowed('2.0.16')
  const isMobileApp = isInMobileAppWebview()
  const sidebarVisible = useSidebarVisible()
  const dispatch = useDispatch()
  useClearLoop()
  // Show sidebar button if not in native mobile app or native feature is not enabled
  const showSidebarButton = !isMobileApp || !isNativeFeatureEnabled

  useLayoutEffect(() => {
    if (pbvisionDiv) {
      pbvisionDiv.style.backgroundColor = COLORS['neutral-100']
    }

    return () => {
      if (pbvisionDiv) {
        pbvisionDiv.style.backgroundColor = ''
      }
    }
  }, [pbvisionDiv])

  useEffect(() => {
    if (sidebarVisible) {
      setIsExpanded(true)
      // Reset sidebar state for the next trigger
      dispatch(resetSidebar())
    }
  }, [dispatch, sidebarVisible])

  const isShotExplorer = activeTab === 'explore'

  const VIDEO_PAGE_SETTINGS = {
    showTheaterControl: true,
    showRallyNav: !isShotExplorer,
    disableDeadTime: isShotExplorer ? !noFilter : false,
    showTabView: false,
    showHighlights: !isShotExplorer,
    showDeadTimeToggle: !isShotExplorer
  }

  // Decide active tab based on the URL tab param
  const isVideoTab = activeTab === 'overview' || activeTab === 'explore'
  const isPlayerInsightsTab = activeTab === 'player'
  const isGameStatsTab = activeTab === 'game-stats'
  const isTeamStatsTab = activeTab === 'team-stats'

  return (
    <HomeAPIWrapper muxPlayerRef={muxPlayerRef}>
      <Container className={clsx(['home', { mobile: isMobile, explorer: isShotExplorer }])}>
        {showSidebarButton && (
          <IconButton onClick={() => setIsExpanded(!isExpanded)} className='sidebar-menu-icon'>
            <AnaliticsIcon />
          </IconButton>
        )}
        <HomeSidebar isOpen={isExpanded} isShotExplorer={isShotExplorer} setIsExpanded={setIsExpanded} />
        <HomePageLayout>
          {isVideoTab && !isShotExplorer && <TitleBar />}
          {isVideoTab && <HomeTab PAGE_SETTINGS={VIDEO_PAGE_SETTINGS} isShotExplorer={isShotExplorer} />}
          {isPlayerInsightsTab && <SinglePlayerInsightsTab />}
          {isGameStatsTab && <GameStatsTab />}
          {isTeamStatsTab && <TeamStatsTab />}
        </HomePageLayout>
        <TaggingDialog />
      </Container>
    </HomeAPIWrapper>
  )
}

export default InsightsPage

const Container = styled('div')({
  maxWidth: '100vw',
  padding: '0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: 300,
  '&.mobile': {
    width: '100% !important'
  },
  '& .sidebar-menu-icon': {
    position: 'fixed',
    top: 150,
    left: 0,
    zIndex: 99,
    padding: 6,
    backgroundColor: COLORS['neutral-300'],
    boxShadow: '1px 3px 6px 0px rgb(0 0 0 / 32%)',
    borderRadius: 0,
    borderTopRightRadius: theme.shape.borderRadiusSmall,
    borderBottomRightRadius: theme.shape.borderRadiusSmall,
    color: COLORS['neutral-500']
  },
  [theme.breakpoints.down('xl') && theme.breakpoints.up('sm')]: {
    marginLeft: '240px', // 240 is the width of the sidebar
    width: 'calc(100% - 240px) !important'
  },
  [theme.breakpoints.down('sm')]: {
    '& h3': {
      fontSize: 18
    }
  }
})
