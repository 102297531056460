import { styled } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

import { ExperimentalIcon } from '../experimental-icon'

import { APIContext } from '@/utils/api'
import COLORS from '@/utils/colors'
import theme from '@/utils/theme'

function displayPlayerName (fullName, id) {
  if (!fullName) {
    return `Player ${id}`
  }
  const name = fullName.split(' ')
  return name[0]
}

const scoreboardTooltip = 'Scoring is currently in beta, so you may notice some inaccuracies. It is only available for games played to 11 or 15 points—rally scoring is not yet supported. To help us improve, please update the true scores in the dropdown on the overview section and report any incorrect scores through our support.'
export default function Scoreboard () {
  const { muxPlayerRef, insights, video } = useContext(APIContext)
  const [currentScore, setCurrentScore] = useState([0, 0])
  const players = video.userData.players

  useEffect(() => {
    if (muxPlayerRef.current && insights?.rallies) {
      const player = muxPlayerRef.current

      const handleTimeUpdate = () => {
        const currentTime = player.currentTime * 1000 // Convert to ms

        // Find all rallies that have ended
        const completedRallies = insights.rallies.filter(rally => rally.end_ms < currentTime)

        if (completedRallies.length > 0) {
          const lastCompletedRally = completedRallies[completedRallies.length - 1] // Get the last completed rally
          setCurrentScore([...lastCompletedRally.running_score]) // Set score from last completed rally
        }
      }

      handleTimeUpdate()
      player.addEventListener('timeupdate', handleTimeUpdate)

      return () => {
        player.removeEventListener('timeupdate', handleTimeUpdate)
      }
    }
  }, [insights?.rallies, muxPlayerRef])

  return (
    <Container className='scoreboard'>
      <div className='team'>
        <div className='team-name'>
          <div className='player-name'>
            <p className='body-sm'>{displayPlayerName(players[0]?.name, 1)}</p>
          </div>
          /
          <div className='player-name'>
            <p className='body-sm'>{displayPlayerName(players[1]?.name, 2)}</p>
          </div>
        </div>
        {/* <div className='serve'>{Array.from({ length: remainingServices[0] }).map((_, i) => <div key={i} className='green-dot' />)}</div> */}
        <div className='team-score body-sm'>{currentScore[0]} </div>
      </div>
      <div className='divider' />
      <div className='team'>
        <div className='team-name'>
          <div className='player-name'>
            <p className='body-sm'>{displayPlayerName(players[2]?.name, 3)}</p>
          </div>
          /
          <div className='player-name'>
            <p className='body-sm'>{displayPlayerName(players[3]?.name, 4)}</p>
          </div>
        </div>
        {/* <div className='serve'>{Array.from({ length: remainingServices[1] }).map((_, i) => <div key={i} className='green-dot' />)}</div> */}
        <div className='team-score body-sm'>{currentScore[1]}</div>
      </div>
      <div className='help'>
        <ExperimentalIcon iconProps={{ width: 18, height: 18 }} title={scoreboardTooltip} />
      </div>
    </Container>
  )
}

const Container = styled('div')({
  position: 'absolute',
  pointerEvents: 'all',
  top: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  background: '#00000075',
  borderRadius: '8px',
  margin: '7px 8px',
  zIndex: 10,
  '& .team': {
    display: 'flex',
    flexDirection: 'row'
  },
  '& .team-name': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '140px',
    gap: '4px',
    padding: '4px 8px',
    '& .player-name': {
      maxWidth: '50%',
      whiteSpace: 'nowrap',
      '& p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: COLORS.white
      }
    }
  },
  '& .team-score': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 8px',
    color: COLORS.white,
    fontWeight: 'bold',
    width: '30px',
    backgroundColor: '#00000080',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px'
  },
  '& .serve': {
    display: 'flex',
    flexDirection: 'row',
    gap: '2px',
    marginLeft: 'auto',
    justifyContent: 'flex-start',
    paddingLeft: '8px',
    alignItems: 'center',
    width: '30px'
  },
  '& .divider': {
    height: '1px',
    backgroundColor: COLORS['neutral-400']
  },
  '& .green-dot': {
    width: '5px',
    height: '5px',
    backgroundColor: COLORS['Quality_scale-Excellent'],
    borderRadius: '50%'
  },
  '& .help': {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '24px',
    height: '26px',
    background: '#ffffff50',
    bottom: -26,
    left: 10,
    cursor: 'pointer',
    borderBottomRightRadius: '8px',
    borderBottomLeftRadius: '8px'
  },
  [theme.breakpoints.down('md')]: {
    '& .team-name': {
      width: '120px',
      padding: '0px 8px'
    },
    '& .body-sm': {
      fontSize: '12px'
    },
    '& .MuiTooltip-popper': {
      width: '80vw',
      transform: 'translate3d(-26px, 11px, 0px) !important'
    }
  }
})
